<ion-header>
    <ion-toolbar color="primary" style="border-radius: 20px 20px 0px 0px">
        <ion-title>CGU</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onClose()">Fermer</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="m-10">
        <p class="font-bold">Conditions Générales d’Utilisation de l’Application Mobile MARGARET</p>
        <p class="font-bold">ARTICLE 1 : INTRODUCTION</p>

        <p>
            La société MARGARET, SAS au capital de 200.000 €, inscrite au RCS de DOUAI sous le
            numéro B 894 554 575, dont le numéro de SIRET est 89455457500015, dont le siège social
            se situe 21 rue Sadi Carnot à LIGNY EN CAMBRESIS (59191), exploite l’Application Mobile
            « MARGARET » ci-après dénommée « l’Application Mobile » o « le Service » ; Le directeur
            de la publication du Service est Monsieur François-Xavier JETTE. L’adresse électronique
            du contact est : contact@margaret.app
        </p>

        <p class="font-bold">Article 2 : OBJET ET CHAMP D’APPLICATION DES CGU</p>

        <p>
            Les présentes Conditions Générales d’Utilisation décrites ci-après et ci-après dénommées
            « CGU », régissent les rapports entre MARGARET et les Utilisateurs, ci-après dénommés «
            l’Utilisateur » ou « les Utilisateurs » de l’Application Mobile, et s’appliquent sans
            restriction ni réserve pour toute utilisation ou téléchargement de l’Application
            Mobile.L’utilisateur est tenu d’accepter les présentes CGU pour tout téléchargement et
            pour toute utilisation de l’Application Mobile en cochant la case « Je reconnais avoir
            lu et accepté les Conditions Générales d’Utilisation ».Les présentes CGU sont
            accessibles à tout moment dans l’application mobile dans la rubrique « C.G.U » qui se
            trouve dans la home page et prévaudront, le cas échéant, sur toute autre version ou tout
            autre document contradictoire. MARGARET pourra modifier les présentes CGU à tout moment,
            sans préavis. Les Utilisateurs seront informés de la modification des présentes par le
            biais de la publication des CGU actualisées sur l’application et par une notification
            signalant ce changement au sein de l’application. Les CGU modifiées prendront effet à
            partir de leur publication. La poursuite de l’utilisation du Service suite à cette
            publication constituera une acceptation de la nouvelle version des présentes.L’Hébergeur
            de l’Application Mobile Clever Cloud, SAS, 3 RUE DE L ALLIER 44000 NANTES - FRANCE, +33
            (0)2 85 52 07 69.Il est donc conseillé aux Utilisateurs de lire très attentivement les
            présentes CGU et de consulter régulièrement les CGU mises à jour, dans la Rubrique «
            C.G.U » de l’Application Mobile et sur l’onglet « Conditions générales d’utilisation »
            du site MARGARET (https://margaret.app).Dans le cas où un Utilisateur n’accepterait pas
            les présentes CGU ou refuserait de s’y conformer, il ne doit pas utiliser l’Application
            Mobile. Dans ce cas, il est recommandé́ de désinstaller l’Application Mobile. En
            procédant à son Inscription, l’Utilisateur confirme avoir pris connaissance des
            présentes CGU et accepte de s'y soumettre sans réserve.Sauf preuve contraire, les
            données enregistrées dans le système informatique de l’Application Mobile constituent la
            preuve de l’ensemble des transactions concluesavec les Utilisateurs.
        </p>

        <p class="font-bold">ARTICLE 3 : PRESENTATION GENERALE DU SERVICE</p>

        <p class="font-bold">3.1 Objet du service</p>

        <p class="font-bold">3.2 Fonctionnement du service</p>

        <p class="font-bold">3.2.1. Règles générales</p>

        <p>
            Tout Visiteur et tout Organisateur déclare être informé qu’il devra, pour accéder au
            Service MARGARET, disposer d’un accès à l’Internet souscrit auprès du fournisseur de son
            choix, dont le coût est à sa charge, et reconnaît que :
        </p>
        <ul>
            <li>
                La fiabilité des transmissions est aléatoire en raison, notamment, du caractère
                hétérogène des infrastructures et réseaux sur lesquelles elles circulent et que, en
                particulier, des pannes ou saturations peuvent intervenir ;
            </li>
            <li>
                Il appartient à l’Organisateur de prendre toute mesure qu’il jugera appropriée pour
                assurer la sécurité de son équipement et de ses propres données, logiciels ou
                autres, notamment contre la contamination par tout virus et/ou de tentative
                d’intrusion dont il pourrait être victime ;
            </li>
            <li>
                Tout équipement connecté au Site est et reste sous l’entière responsabilité de
                l’Organisateur, la responsabilité de MARGARET ne pourra pas être recherchée pour
                tout dommage direct ou indirect qui pourrait subvenir du fait de leur connexion au
                Site Internet
            </li>
        </ul>
        <p>
            L’Organisateur s’engage, le cas échéant, à respecter et à maintenir la confidentialité
            des Identifiants de connexion à son Compte Organisateur et reconnaît expressément que
            toute connexion à son Compte Organisateur, ainsi que toute transmission de données
            depuis son Compte Organisateur sera réputée avoir été effectuée par l’Organisateur.Toute
            perte, détournement ou utilisation des Identifiants de connexion et leurs éventuelles
            conséquences relèvent de la seule et entière responsabilité de
            l’Organisateur.L'Organisateur est informé et accepte que pour des raisons d'ordre
            technique, son Evénement ne sera pas diffusée instantanément après son dépôt sur le Site
            et les Applications. L'Organisateur est informé qu'en publiant son Evénement sur le
            Site, celui-ci est susceptible d'être partagée par tout Visiteur et/ou tout Organisateur
            sur le réseau social Facebook, via le bouton de partage concerné intégré dans les
            pages.Tout Evénement publié sera diffusé sur son Site et ses Applications mais aussi sur
            les sites et applications de partenaires.Tout Organisateur est informé que MARGARET est
            susceptible de diffuser des Evénements sur des sites, applications et médias extérieurs
            à MARGARET.Tout Organisateur accepte que MARGARET puisse diffuser les Evénements sur des
            services dédiés et dans la limite de leur durée de vie ou jusqu’à leur suppression.
        </p>

        <p class="font-bold">3.2.2. fonctionnalités</p>

        <p>
            Le Service MARGARET proposé aux Visiteurs et aux Organisateurs peut varier en fonction
            du support de communication utilisé (Site Internet ou Applications).
        </p>

        <p class="font-bold">
            3.2.2.1 Fonctionnalités accessibles aux Visiteurs non logués depuis le Site et les
            Applications
        </p>

        <p>
            La consultation de tous les Evénements diffusésLa possibilité de conseiller l'Evénement
            à un amiSignaler un abusLa sauvegarde d’un Evénement. La possibilité d'enregistrer
            l’Evénement dans son calendrier (google, iCal, Outlook)La géolocalisation des Evénements
            publiésLa consultation des statistiques des Evénements
        </p>

        <p class="font-bold">
            3.2.2.2 Fonctionnalités accessibles aux Visiteurs logués depuis le Site et les
            Applications
        </p>

        <p>
            La sauvegarde de mes recherchesLa réception de messages d’alerte par Notification par
            courriel.La liste des Evénements ajouté à son agenda
        </p>

        <p class="font-bold">
            3.2.2.3) Fonctionnalités accessibles depuis le Site aux Organisateurs
        </p>

        <p>
            La rédactions d’Evénements (brouillon)La validation des Evénements (à rendre public)La
            gestion des Evénements publiés:
        </p>
        <ul>
            <li>la suppression d’un Evénement</li>
            <li>l’annulation d’un Evénement</li>
            <li>la modification d’Evénement</li>
            <li>la duplication de l’Evénement</li>
            <li>Le signalement que l’Evénement est complet</li>
        </ul>
        <p>
            L’historique des Evénements passésL'accès et la gestion du Compte Organisateur : La
            gestion (actualisation, modification etc.), à tout moment, des informations personnelles
            renseignées lors de la création du représentant légal du Compte Organisateur
            (Informations obligatoires : nom, prénom, email, numéro de téléphone, mot de passe,
            numéro de SIRET ou RNA)L'ajout d’un logo ou photographie, d’un lieu habituel, des
            coordonnées de la personne morale, ses comptes dans l'onglet "Profil" du Compte
            Organisateur, la possibilité de la modifier et de la supprimer. Celle-ci sera visible
            (ou pas) par les Visiteurs sur le profil public de l'Organisateur concerné et sur chacun
            de ses Evénements en ligne.Les statistiques de vues, enregistrement, de personnes
            présentes et d’évaluation en cliquant sur le bouton « Stats ».
        </p>

        <p class="font-bold">
            3.2.2.4) Fonctionnalités depuis le Site accessibles aux Clients logués
        </p>

        <p>La consultation de :</p>
        <ul>
            <li>ses achats</li>
            <li>ses échéances</li>
            <li>ses factures</li>
            <li>ses ses moyens de paiement</li>
        </ul>

        <p class="font-bold">3.2.2.5) Notifications</p>

        <p class="font-bold">3.2.2.5.1 Conditions de réception des Notifications</p>

        <p>
            Seuls les Visiteurs connectés à leur Compte Personnel peuvent recevoir des Notifications
            depuis leur Application dans les cas définis au 3.2.2 et 3.2.3.Toutefois, il est rappelé
            que les Visiteurs ne peuvent pas recevoir de Notifications de nouveaux messages via la
            Messagerie, celle-ci ne leur étant pas accessible.
        </p>

        <p class="font-bold">3.2.2.5.2 Paramétrage des Notifications</p>

        <p>
            - Sur l’Application AndroidLes Visiteurs utilisant l’Application Android peuvent depuis
            le paramétrage de leur téléphone Android et/ou de leur tablette accepter et/ou refuser
            la réception de toutes Notifications. Par défaut, la réception des Notifications est
            activée sur l’Application Android.- Sur l’Application IOSLes Visiteurs utilisant
            l’Application IOS peuvent depuis le paramétrage de leur iPhone et iPad accepter et/ou
            refuser la réception de toutes Notifications. Par défaut, la réception de Notifications
            est désactivée sur l’Application IOS.
        </p>

        <p class="font-bold">ARTICLE 4 : CREATION D’UN COMPTE PERSONNEL D’UTILISATEUR</p>

        <p>Le visiteur pourra créer un compte en renseignant les éléments ci-dessous :</p>

        <ul>
            <li>Prénom</li>
            <li>Nom</li>
            <li>Adresse</li>
            <li>Numéro de téléphone</li>
            <li>Création d’un mot de passe</li>
        </ul>

        <p class="font-bold">ARTICLE 5 : TARIFS ET MODALITES FINANCIERES :</p>

        <p>
            Le téléchargement et l’utilisation de l’Application Mobile MARGARET sont gratuits.
            L’Application Mobile téléchargée gratuitement permet d’accéder à tous les services
            proposés tels que décrits à l’Article 3 des présentes.
        </p>

        <p class="font-bold">ARTICLE 6 : OBLIGATIONS DE L’UTILISATEUR ET DE L’ORGANISATEUR</p>

        <p class="font-bold">6.1 OBLIGATIONS DE L’UTILISATEUR</p>

        <p class="font-bold">6.2 OBLIGATIONS DE L’ORGANISATEUR</p>

        <p>
            L'Organisateur garantit détenir tous les droits (notamment des droits de propriété
            intellectuelle) ou avoir obtenu toutes les autorisations nécessaires à l’organisation et
            à la publication de son Evénement.
        </p>

        <p>
            L'Organisateur garantit que l'Evénement ne contrevient à aucune réglementation en
            vigueur (notamment relatives à l’ordre public, à la publicité, à la concurrence, à la
            promotion des ventes, à l'utilisation de la langue française, à l'utilisation de données
            personnelles, à la prohibition de la commercialisation de services), ni aucun droit de
            tiers (notamment aux droits de propriété intellectuelle et aux droits de la
            personnalité) et qu'il ne comporte aucun message diffamatoire ou dommageable à l'égard
            de tiers. Ainsi, l'Organisateur s'engage notamment à ce que l'Evénement ne contienne :
        </p>
        <ul>
            <li>
                aucune information fausse, mensongère ou de nature à induire en erreur les Visiteurs
            </li>
            <li>
                aucune mention diffamatoire ou de nature à nuire aux intérêts et/ou à l'image de
                MARGARET ou de tout tiers
            </li>
            <li>aucun contenu portant atteinte aux droits de propriété intellectuelle de tiers</li>
            <li>
                aucun contenu à caractère promotionnel ou publicitaire en lien avec l'activité de
                l'Organisateur.
            </li>
        </ul>

        <p>
            En effet, un Evénement est destinée à partager un moment et n'est pas un support de
            publicité pour des produits, biens ou services L'Organisateur s'engage à proposer des
            Evénements dont il est directement responsable de son organisation. L'Organisateur
            s'engage, en cas d’annulation, d’indisponibilité, de réservation complète, à procéder au
            retrait ou modification de l'Evénement du Service MARGARET dans les plus brefs délais.
            L’Organisateur s’engage à proposer une description de l’Evénement conforme aux états
            suivants :
        </p>

        <ul>
            <li>
                Date et heure de début - date et heure de fin (à défaut heure prévisionnelle de fin)
            </li>
            <li>Lieu exact de l’Evénement</li>
            <li>Catégorie de l’Evénement</li>
            <li>Titre (descriptif rapide)</li>
            <li>
                Détail (descriptif complet comprenant le tarif, les disponibilités, les conditions
                d’accès, les services disponibles sur site, le tout en 7 langues si besoin)
            </li>
            <li>Affiche (image, pictogramme, photo respectant les droits d’auteurs)</li>
        </ul>
        <p>
            L'Organisateur déclare connaître l'étendue de diffusion du Site, avoir pris toutes
            précautions pour respecter la législation en vigueur des lieux de réception et décharger
            MARGARET de toutes responsabilités à cet égard. Dans ce cadre, l'Organisateur déclare et
            reconnaît qu'il est seul responsable du contenu des Evénements qu'il publie et rend
            accessibles aux Visiteurs, ainsi que de tout document ou information qu'il transmet aux
            Visiteurs. L'Organisateur assume l'entière responsabilité éditoriale du contenu des
            Evénements qu'il publie. En conséquence, l'Organisateur relève MARGARET, ses
            sous-traitants et fournisseurs, de toutes responsabilités, les garantit contre tout
            recours ou action en relation avec l'Evénement qui pourrait être intenté contre ces
            derniers par tout tiers, et prendra à sa charge tous les dommages-intérêts ainsi que les
            frais et dépenses auxquels ils pourraient être condamnés ou qui seraient prévus à leur
            encontre par un accord transactionnel signé par ces derniers avec ce tiers , nonobstant
            tout dommages-intérêts dont MARGARET, ses sous-traitants et fournisseurs pourraient
            réclamer à raison des faits dommageables de l’Organisateur. En déposant tout Evénement,
            chaque Organisateur reconnaît et accepte que MARGARET puisse supprimer, ou refuser, à
            tout moment, sans indemnité, un Evénement qui serait contraire notamment à la loi
            française, aux règles de diffusion du Service MARGARET fixées par MARGARET et/ou
            susceptible de porter atteinte aux droits de tiers. De manière générale, il est de la
            responsabilité des Organisateurs de vérifier leur statut et responsabilités surtout sur
            les normes d’accès au public et sur la déclaration préalable d’utilisation du domaine
            public.
        </p>

        <p>
            Tout Evénement est diffusé, à compter du jour de son dépôt, simultanément sur le Site
            Internet et les Applications et ce jusqu’au jour de l’Evénement L'Organisateur s'engage
            également à ce que son Compte Organisateur ne contienne :
        </p>

        <ul>
            <li>aucune information obligatoire fausse et/ou mensongère</li>
            <li>aucune information portant atteinte aux droits d'un tiers</li>
            <li>
                aucune photographie (i) pour laquelle il ne détient pas les droits de propriété
                intellectuelle, (ii) sur laquelle d'autres personnes que lui-même sont visibles à
                moins que ces personnes aient donné leur accord pour la publication de ces
                photographies, (iii) sur laquelle des données à caractère personnel sont visibles ou
                (iv) qui sont ou sont susceptibles d'être considérées comme des photographies
                érotiques ou pornographiques, ou contraires aux bonnes mœurs ou à l'ordre public.
                Dans ce cadre, le titulaire déclare et reconnaît qu'il est seul responsable des
                informations renseignées lors de la création de son Compte Organisateur. En créant
                un Compte Organisateur, chaque titulaire reconnaît et accepte que MARGARET puisse
                supprimer, à tout moment, sans indemnité un compte qui serait contraire notamment à
                la loi française et/ou aux règles de diffusion fixées par MARGARET. L’Organisateur
                reconnait et accepte que MARGARET puisse lui demander tout document permettant de
                vérifier la qualité renseignée lors de la création de son Compte Organisateur et la
                détention des droits et/ou l’obtention des autorisations nécessaires à la
                publication de son Evénement. A défaut de communication des justificatifs demandés,
                MARGARET est en droit de supprimer, ou suspendre à tout moment, sans indemnité le
                compte Organisateur.
            </li>
        </ul>

        <p class="font-bold">ARTICLE 7 : OBLIGATIONS DE MARGARET</p>

        <p>
            En sa qualité d'hébergeur, MARGARET est soumise à un régime de responsabilité atténuée
            prévu aux articles 6.I.2. et suivants de la loi nº2004-575 du 21 juin 2004 pour la
            confiance dans l'économie numérique. MARGARET ne saurait donc en aucun cas être tenue
            responsable du contenu des Evénements publiées par les Organisateurs et ne donne aucune
            garantie, expresse ou implicite, à cet égard. MARGARET est un tiers aux correspondances
            et relations entre les Organisateurs et les Visiteurs, et exclut de ce fait toute
            responsabilité à cet égard.
        </p>

        <p class="font-bold">ARTICLE 8 : MODERATION DES EVENEMENTS</p>

        <p class="font-bold">8.1. Suppression des Evénements illicites</p>

        <p>
            MARGARET et les Organisateurs se réservent le droit de suspendre sans préavis ni
            indemnité ni droit à remboursement, tout Evénement qui ne serait pas conforme aux règles
            de diffusion du Service MARGARET et/ou qui serait susceptible de porter atteinte aux
            droits d'un tiers.
        </p>

        <p class="font-bold">8.2. Notification des abus</p>

        <p>
            Il est permis à tout Visiteur de signaler un contenu abusif à partir du Site et des
            Applications :
        </p>

        <ul>
            <li>
                soit en cliquant sur le lien "signaler un contenu inapproprié" situé sur chaque page
                d'Evénement.
            </li>
            <li>soit par mail en cliquant sur le lien « contact »</li>
        </ul>

        <p class="font-bold">8.3. Poursuites</p>

        <p>
            MARGARET se réserve le droit de poursuivre les Organisateurs pour atteinte à l’image et
            la réputation de MARGARET, pour perte d’exploitation et à demander aux Organisateurs la
            réparation des préjudices subis.
        </p>

        <p class="font-bold">ARTICLE 9 : LIMITATION DE RESPONSABILITES</p>

        <p>
            MARGARET s'engage à mettre en œuvre tous les moyens nécessaires afin d'assurer au mieux
            la fourniture du Service MARGARET aux Visiteurs et aux Organisateurs.Toutefois, MARGARET
            décline toute responsabilité en cas de :
        </p>
        <ul>
            <li>
                interruptions, de pannes, de modifications et de dysfonctionnement du Service
                MARGARET quel que soit le support de communication utilisé et ce quelles qu'en
                soient l'origine et la provenance,
            </li>
            <li>
                la perte de données ou d'informations stockées par MARGARET. Il incombe aux
                Organisateurs de prendre toutes précautions nécessaires pour conserver les
                Evénements qu'ils publient via le Service MARGARET;
            </li>
            <li>
                impossibilité momentanée d'accès au Site Internet et/ou aux Applications en raison
                de problèmes techniques et ce quelles qu'en soient l'origine et la provenance,
            </li>
            <li>
                dommages directs ou indirects causés au Visiteur ou à l'Organisateur, quelle qu'en
                soit la nature, résultant du contenu des Evénements et/ou de l'accès, de la gestion,
                de l'Utilisation, de l'exploitation, du dysfonctionnement et/ou de l'interruption du
                Service MARGARET,
            </li>
            <li>
                utilisation anormale ou d'une exploitation illicite du Service MARGARET par tout
                Visiteur ou Organisateur,
            </li>
            <li>
                attaque ou piratage informatique, privation, suppression ou interdiction, temporaire
                ou définitive, et pour quelque cause que ce soit, de l’accès au réseau internet.La
                responsabilité de MARGARET ne pourra être engagée que pour les dommages directs
                subis par l’Organisateur, résultant d’un manquement à ses obligations contractuelles
                telles que définies aux présentes. Le Visiteur et l’Organisateur renoncent donc à
                demander réparation à MARGARET à quelque titre que ce soit, de dommages indirects
                tels que le manque à gagner, la perte de chance, le préjudice commercial ou
                financier, l’augmentation de frais généraux ou les pertes trouvant leur origine ou
                étant la conséquence de l’exécution des présentes.Tout Visiteur et Organisateur est
                alors seul responsable des dommages causés aux tiers et des conséquences des
                réclamations ou actions qui pourraient en découler. Le Visiteur renonce également à
                exercer tout recours contre MARGARET dans le cas de poursuites diligentées par un
                tiers à son encontre du fait de l'Utilisation et/ou de l'exploitation illicite du
                Service MARGARET en cas de perte par un Visiteur ou un Organisateur de son mot de
                passe ou en cas d'usurpation de son identité.
            </li>
        </ul>

        <p class="font-bold">ARTICLE 10 : DUREE</p>

        <p>
            Dans le cas où le téléchargement de l’application Mobile gratuite, les présentes CGU
            sont souscrites pour une durée indéterminée à compter de leur acceptation par
            l’Utilisateur et l’Organisateur dans les conditions décrites à l’Article 1. ARTICLE 11 :
            RESILIATION L'accès à l’Application Mobile et au Service pourrait être interrompu
            temporairement ou définitivement, notamment en cas de cessation par MARGARET de
            l'activité́ en cause, ou en cas de liquidation judiciaire ou amiable de la société́
            MARGARET. Les présentes CGU seraient alors résiliées de plein droit. En cas de décès de
            l’Utilisateur ou de l’Organisateur, les relations contractuelles entre celui-ci et
            MARGARET seront résiliées de plein droit et son Compte personnel sera désactivé́, sur
            production des pièces justificatives pertinentes des ayants droit. Son contenu ne pourra
            être transmis aux ayants droit que sur décision de Justice. En cas de violation des
            présentes par l’Utilisateur, ou des lois et règlements en vigueur, notamment, mais pas
            exclusivement, en cas de manquement par un Utilisateur ou par un Organisateur à l'une ou
            plusieurs des règles précitées à l’Article 6, ou en cas de comportement compromettant ou
            risquant de compromettre le bon fonctionnement du Service ou porter atteinte aux droits
            des tiers ou à la réputation du Service, MARGARET se réserve le droit de suspendre et/ou
            de résilier unilatéralement l’exécution des présentes, et de bloquer et/ou supprimer le
            Compte personnel de l’Utilisateur ou Organisateur concerné, ainsi que son accès à tout
            ou partie du Service, de supprimer les contenus litigieux, de façon temporaire ou
            définitive, sans que cela ouvre droit pour l’Utilisateur ou l’Organisateur à une
            quelconque indemnité́, et sans préjudice de toute action en Justice que MARGARET pourrait
            intenter par la suite pour faire valoir ses droits. Tout Utilisateur ou Organisateur
            peut se désinscrire à tout moment de l’Application Mobile en suivant la procédure
            suivante : 1. Connexion à l’Application Mobile et saisie du login et mot de passe pour
            s’identifier ; 2. Clic sur l'onglet "Mon profil" ; 3. Cocher la case « Désactiver mon
            profil », située au bas de la page ARTICLE 11 : PROPRIETE INTELLECTUELLE ET INDUSTRIELLE
            7.1 Elements Tous les droits de propriété intellectuelle (tels que notamment droits
            d'auteur, droits voisins, droits des marques, droits des producteurs de bases de
            données) portant tant sur la structure que sur les contenus du Site et des Applications
            et notamment les images, sons, vidéos, photographies, logos, marques, éléments
            graphiques, textuels, visuels, outils, logiciels, documents, données, etc. (ci-après
            désignés dans leur ensemble " Eléments ") sont réservés. Ces Eléments sont la propriété
            de MARGARET. Ces Eléments sont mis à disposition des Visiteurs et des Organisateurs, à
            titre gracieux, pour la seule utilisation du Service MARGARET et dans le cadre d'une
            utilisation normale de ses fonctionnalités. Les Visiteurs et les Organisateurs
            s'engagent à ne modifier en aucune manière les Eléments. Toute utilisation non
            expressément autorisée des Eléments du Site Internet et des Applications entraîne une
            violation des droits d'auteur et constitue une contrefaçon. Elle peut aussi entraîner
            une violation des droits à l'image, droits des personnes ou de tous autres droits et
            réglementations en vigueur. Elle peut donc engager la responsabilité civile et/ou pénale
            de son auteur. 7.2 code source Il est interdit à tout Visiteur et Organisateur de
            copier, modifier, créer une œuvre dérivée, inverser la conception ou l'assemblage ou de
            toute autre manière tenter de trouver le code source, vendre, attribuer, sous licencier
            ou transférer de quelque manière que ce soit tout droit afférent aux Eléments. Tout
            Visiteur et Organisateur du Service MARGARET s'engagent notamment à ne pas :
        </p>

        <ul>
            <li>
                utiliser ou interroger le Service MARGARET pour le compte ou au profit d'autrui ;
            </li>
            <li>
                extraire, à des fins commerciales ou non, tout ou partie des informations ou des
                Evénements présents sur le Service MARGARET et sur le Site et les Applications ;
            </li>
            <li>
                reproduire sur tout autre support, à des fins commerciales ou non, tout ou partie
                des informations ou des Evénements présentes sur le Service MARGARET et sur le Site
                Internet et les Applications permettant de reconstituer tout ou partie des fichiers
                d'origine ;
            </li>
            <li>
                utiliser un robot, notamment d'exploration, une application de recherche ou
                récupération de sites Internet ou tout autre moyen permettant de récupérer ou
                d'indexer tout ou partie du contenu du Site Internet et des Applications, excepté en
                cas d'autorisation expresse et préalable de MARGARET;
            </li>
            <li>
                copier les informations sur des supports de toute nature permettant de reconstituer
                tout ou partie des fichiers d'origine.
            </li>
        </ul>
        <p>
            Toute reproduction, représentation, publication, transmission, utilisation, modification
            ou extraction de tout ou partie des Eléments et ce de quelque manière que ce soit, faite
            sans l'autorisation préalable et écrite de MARGARET est illicite. Ces actes illicites
            engagent la responsabilité de ses auteurs et sont susceptibles d'entraîner des
            poursuites judiciaires à leur encontre et notamment pour contrefaçon. 7.3. Marque Les
            marques et logos MARGARET et MARGARET.app, MARGARET.fr ainsi que les marques et logos
            des partenaires de MARGARET sont des marques déposées. Toute reproduction totale ou
            partielle de ces marques et/ou logos sans l'autorisation préalable et écrite de MARGARET
            est interdite. 7.4. Base de données MARGARET est producteur des bases de données du
            Service MARGARET. En conséquence, toute extraction et/ou réutilisation de la ou des
            bases de données au sens des articles L 342-1 et L 342-2 du code de la propriété
            intellectuelle est interdite. 7.5. saisie MARGARET se réserve la possibilité de saisir
            toutes voies de droit à l'encontre des personnes qui n'auraient pas respecté les
            interdictions contenues dans le présent article. 7.6. Liens hypertextes 7.6.1. Liens à
            partir du Service MARGARET Le Service MARGARET peut contenir des liens hypertextes
            redirigeant vers des sites exploités par des tiers. MARGARET n'exerce aucun contrôle sur
            ces sites et décline toute responsabilité quant à l'accès, au contenu ou à l'utilisation
            de ces sites, ainsi qu'aux dommages pouvant résulter de la consultation des informations
            présentes sur ces sites. La décision d'activer ces liens relève de la pleine et entière
            responsabilité du Visiteur. 7.6.2. Liens vers le Service MARGARET Aucun lien hypertexte
            ne peut être créé vers le Service MARGARET sans l'accord préalable et exprès de
            MARGARET. Si un internaute ou une personne morale désire créer, à partir de son site, un
            lien hypertexte vers le Service MARGARET et ce quel que soit le support, il doit
            préalablement prendre contact avec MARGARET en lui adressant un email à l'adresse
            suivante support@margaret.app. Tout silence de MARGARET devra être interprété comme un
            refus. 7.7. propriété des Evénements Le contenu des Evénements déposées appartient aux
            Organisateurs, néanmoins, en déposant des Evénements sur le Site Internet,
            l’Organisateur concède : - à MARGARET le droit d’exploitation non exclusif,
            transférable, sous licenciable, à titre gracieux, pour le monde entier sur (i)
            l’ensemble du contenu des Evénements et notamment sur les photographies, textes, vidéos,
            illustrations, marques, logos, titres (ci-après le « Contenu »), au fur et à mesure de
            leur publication sur le Site ainsi (ii) qu’une licence sur l’ensemble des droits de
            propriété intellectuelle afférent au Contenu et notamment sur les droits d’auteurs sur
            les éléments utilisés dans son Evénement, tels que les photographies, textes, vidéos,
            dessins, illustrations, éléments sonores, et ce pour toute la durée légale de ses droits
            de propriété intellectuelle et pour le monde entier. Les droits ainsi concédés incluent
            le droit de reproduire, représenter, diffuser, adapter, modifier, réaliser une œuvre
            dérivée, traduire tout ou partie du Contenu par tous procédés, sous quelque forme que ce
            soit et sur tous supports (numérique, imprimé…) connus ou inconnus à ce jour, dans le
            cadre du service MARGARET ou en relation avec l’activité de MARGARET, et ce à des fins
            commerciales ou non et notamment publicitaires, ainsi que dans le cadre d’une diffusion
            sur les réseaux sociaux sur lesquels MARGARET est présent et notamment les pages
            Facebook, Instagram et Twitter de MARGARET. En particulier, les photographies des
            Evénements pourront être reproduites et intégrées dans des formats publicitaires
            diffusés sur le Site Internet, uniquement en lien avec l’Evénement déposée.
            L’Organisateur accorde son consentement à la reprise de son Evénement et du Contenu de
            cette dernière sur les réseaux sociaux, notamment Facebook, Instagram et Twitter. Par
            conséquent, l’Organisateur atteste avoir pris connaissance des conditions générales
            d’utilisation des sites Facebook, Instagram, et Twitter et en accepter les termes,
            particulièrement en matière de réutilisation du Contenu et des données personnelles. Au
            titre de cette licence, MARGARET, sans que cela ne crée à sa charge une obligation
            d’agir, est en droit de s’opposer à la reproduction et l’exploitation par des tiers non
            autorisés des Evénements diffusées sur le Site Internet et de leur Contenu. - aux
            Visiteurs, le droit non exclusif d’accéder au Contenu via le Service MARGARET et
            d’utiliser et de représenter le Contenu dans la mesure autorisée par les fonctionnalités
            du Service MARGARET, et ce pour le monde entier. ». ARTICLE 12 : POLITIQUE DE
            CONFIDENTIALITE ET DONNEES PERSONNELLES Tout traitement de données personnelles dans le
            cadre des présentes est soumis aux dispositions de notre politique de confidentialité,
            qui fait partie intégrante des présentes CGU. 12.1 Généralités Dans le cadre de
            l’exécution de ses prestations, MARGARET est susceptible de collecter des données
            personnelles relatives notamment à la population de personnes physiques objet des
            prestations commandées par les Utilisateurs. Conformément aux dispositions de la loi n°
            78-17 du 6 janvier 1978, modifiée par la loi n° 2004-801 du 6 août 2004, relative à
            l’informatique, aux fichiers et aux libertés Informatique et Libertés du 6 janvier 1978,
            le traitement automatisé des données nominatives réalisées à partir de l’Application
            Mobile et des Sites Web a fait l'objet d'une déclaration auprès de la Commission
            Nationale de l'Informatique et des Libertés (CNIL n°2097760). En application de la loi
            78-17 du 6 janvier 1978 et du Règlement européen 20116/679 du 27 avril 2016 relatif à la
            protection des personnes physiques à l’égard du traitement des données à caractère
            personnel et à la libre circulation de ces données (RGPD), les données personnelles qui
            seront conservées au sein de ce fichier automatisé qui se trouve sous la responsabilité
            de MARGARET, seront utilisées avec pour seule finalité de permettre la fourniture et la
            bonne exécution des services proposés. La collecte des données personnelles recueillies
            par le biais du Service MARGARET est nécessaire au bon fonctionnement du Service et est
            obligatoire pour accéder aux services proposés via l’Application Mobile. Le défaut de
            communication des données personnelles obligatoires empêche l’accès au Service proposé
            par MARGARET. MARGARET réalise la conservation des Données Personnelles de façon à
            permettre le respect de leur intégrité durant le temps nécessaire à la réalisation et la
            fourniture du Service proposé et conformément aux durées de conservation imposées par la
            législation en vigueur. Si l’Utilisateur s’est identifié via son profil Facebook ou
            Google, alors MARGARET pourra avoir accès à son identifiant et à son profil. 12.2
            Obligations de MARGARET dans le cadre de la législation RGPD : MARGARET s’engage à
            traiter lesdites données personnelles dans le respect de cette règlementation, et à cet
            égard, s’engage à : - Ne collecter et traiter les données personnelles que conformément
            aux instructions expresses de l’Utilisateur et aux finalités liées à l’objet des
            services proposés via l’Application Mobile. - Préserver la sécurité, l’intégrité et la
            confidentialité des données personnelles dès lors qu’il procède à leur collecte ou leur
            enregistrement dans le cadre de l’exécution des Services ; - Ne communiquer les données
            personnelles à aucun tiers quel qu’il soit, hormis les tiers auxquels il est strictement
            nécessaire de transmettre les données personnelles en exécution des prestations
            proposées ; Les données personnelles ainsi collectées pourront ainsi être transmises au
            Personnel de MARGARET ainsi qu’à tout tiers chargé de participer à la mise en place, à
            la réalisation ou au suivi du Service (personnel, partenaires et/ou sous-traitants). Les
            membres du Personnel de MARGARET ainsi que les tiers désignés par ce dernier, auront
            accès et pourront utiliser les données personnelles collectées dans le seul but de
            fournir le Service proposé. En aucun cas, les données personnelles ne seront cédées à
            d’autres tiers, que ce soit à titre gracieux ou onéreux, sans l’autorisation de
            l’Utilisateur, à l’exception des cas prévus à l’article 10 des présentes qui prévoit que
            les données permettant l’identification de l’Utilisateur pourront être transmises aux
            ayants droit sur décision de Justice. - N’effectuer aucun transfert de données
            personnelles en dehors du territoire de l’Union européenne, hormis vers des pays tiers
            présentant un niveau de protection adéquat au sens des autorités de contrôle ou vers un
            sous-traitant autorisé par l’Utilisateur et signataire des clauses contractuelles types
            édictées par les autorités européennes ; - Mettre en place tout système de sécurisation
            des données qui serait requis soit en raison d’une analyse d’impact menée par
            l’Utilisateur en tant que responsable du traitement soit en raison d’une législation
            spécifique imposant de recourir à des modalités déterminées de conservation des données
            ; - Alerter sans délai l’Utilisateur en cas de violation, de perte ou de divulgation non
            autorisée de données personnelles collectées dans le cadre de l’utilisation des
            Services, afin de permettre à l’Utilisateur d’alerter les personnes concernées et de se
            conformer à ses obligations au sens de la règlementation susmentionnée. MARGARET
            s’abstient en toute hypothèse de reproduire, exploiter ou utiliser les données
            personnelles collectées à l’occasion de ses prestations ses propres fins ou pour le
            compte de tiers, et s’engage à modifier ou supprimer, soit à la demande de
            l’Utilisateur, soit à la demande d’une personne concernée, et en toute hypothèse à
            l’achèvement de la finalité poursuivie et au terme de l’exécution de ses prestations,
            toute donnée personnelle collectée à l’occasion ou aux fins d’exécution desdites
            prestations. Tout Utilisateur peut envoyer une plainte au sujet du respect de la vie
            privée ou toute question au sujet des informations recueillies et traitées par MARGARET
            à l’adresse électronique suivante : contact@MARGARET.app 12.3 Nature des informations
            collectées Lors de la souscription et l’utilisation du Service, MARGARET ne recueille et
            conserve que certaines informations, à savoir : Prénom, Nom de Famille (seul le prénom
            apparaîtra sur le profil), photo, Email (celui-ci n’apparaîtra pas sur les fiches de
            profils), mot de passe choisi, numéro de téléphone (celui-ci n’apparaîtra pas sur les
            fiches de profils). Si l’Utilisateur s’est identifié via son profil Facebook ou Google,
            alors MARGARET pourra avoir accès à son identifiant et à son profil. 12.4 Droit d’accès,
            de rectification et de suppression des données personnelles Tout Utilisateur a le droit
            d’accéder, de rectifier ou de s’opposer au traitement de toute donnée le concernant qui
            ne soit pas indispensable à la finalité du recueil. Conformément aux articles 39 et
            suivants de la loi n° 78-17 du 6 janvier 1978, modifiée par la loi n° 2004-801 du 6 août
            2004, et à la législation relative à l’informatique, aux fichiers et aux libertés, et au
            Règlement européen 20116/679 du 27 avril 2016 relatif à la protection des personnes
            physiques à l’égard du traitement des données à caractère personnel et à la libre
            circulation de ces données (RGPD), toute personne peut obtenir communication et, le cas
            échéant, rectification ou suppression des informations la concernant, en adressant un
            courrier électronique à l’adresse de contact : contact@MARGARET.app A cet effet, au sein
            des registres informatiques de MARGARET peuvent être conservés les noms ou prénom,
            l’adresse email, le mot de passe, l’adresse IP de l’Utilisateur, la marque et le modèle
            de l’appareil, l’opérateur mobile, la date et l’heure exacte de publication des
            informations et contenus transmises via l’Application Mobile. 12.5 Utilisation des
            cookies, identifiants et du fichier d’activité Afin d’améliorer le Service MARGARET en
            termes de rendement du système et de facilité d’usage, ainsi que pour proposer de
            l’information utile sur les produits et services, peuvent être recueillies
            automatiquement et conservées des informations dans les fichiers de registre (log files)
            en provenance des ordinateurs ou terminaux utilisés. Ceci inclut des données non
            personnelles telles que l’adresse IP, le type de navigateur, langue sélectionnée, le
            système d’exploitation, le fournisseur d’accès Internet (ISP) ainsi que la date et
            l’heure. Ces informations sont utilisées de façon globale pour améliorer la gestion de
            l’Application Mobile, analyser les tendances et recueillir des données démographiques
            sur les Utilisateurs. Si des informations non personnelles sont utilisées en combinaison
            avec les Données Personnelles, l’ensemble sera traité en tant que Données Personnelles
            et sera dès lors traité conformément au présent article 12. De même, dans le but
            d’optimiser les services de MARGARET, les messages envoyés par courrier électronique,
            les services onlines, les publicités et les applications interactives peuvent utiliser
            les “cookies” ou les “identifiants anonymes” des utilisateurs (en ce qui concerne les
            Sites web). Que sont les cookies ? Un cookie est un petit fichier, habituellement formé
            par des lettres et des nombres, envoyés par notre serveur web ou par l’Application
            Mobile à un fichier de cookies du navigateur de l’Utilisateur. Ceci permet à MARGARET de
            se souvenir de la présence d’un Utilisateur lors de l’établissement d’une nouvelle
            connexion entre au serveur de MARGARET et le navigateur de l’Utilisateur. Le principal
            objectif d’un cookie est de permettre au serveur de MARGARET de présenter des pages web
            personnalisées qui puissent faire de la consultation du Service une expérience
            individuelle et ajustée aux préférences personnelles. Que sont les identifiants ou
            sondes ? Un identifiant anonyme est une chaîne de caractères aléatoires qui s’utilise
            avec la même finalité́ que les cookies sur des plateformes sur lesquelles la technologie
            des cookies est indisponible, ceci inclut certains types d’appareils mobiles ainsi que
            les applications installées. 12.6 Obligations de l’Utilisateur Il est rappelé qu’il
            appartient à l’Utilisateur, de recueillir tout consentement nécessaire auprès des
            personnes physiques concernées, en corrélation avec les commentaires et publications de
            toute nature qu’il dispense sur l’’application. En outre, les personnes concernées par
            les publications ou commentaires d’un Utilisateur disposent sur les données personnelles
            les concernant des droits d’accès, de rectification, d’effacement, de limitation, de
            portabilité et d’opposition, et peuvent à tout moment révoquer les consentements aux
            traitements. Les personnes concernées seront susceptibles de faire valoir leurs droits
            directement auprès des Utilisateurs, qui s’engagent le cas échéant à les transmettre à
            MARGARET afin que ces demandes soient traitées dans les meilleurs délais, par envoi d’un
            courriel à l’adresse électronique suivante : contact@MARGARET.app ARTICLE 13 :
            LÉGISLATION APPLICABLE – LITIGES – LANGUE La conclusion, l'interprétation et la validité
            des présentes CGU et l’ensemble des contrats qui en découlent et leurs suites sont
            soumis à la Loi et la Règlementation française en vigueur, à l'exclusion de toute autre
            législation, quelle que soit la nationalité́ de l’Utilisateur, il en est ainsi pour les
            règles de fond et de formes. À défaut d’accord amiable entre les parties et en cas
            d'échec des pourparlers, les parties reprendront leur entière liberté́ et leur litige
            sera porté devant le Tribunal de Commerce de Douai. Notamment, en application du
            Règlement n° 524/2013 relatif au règlement en ligne des litiges de consommation, le
            Client est informé que tout litige issu des présentes conditions générales pourra être
            porté devant la plate-forme de règlement en ligne des litiges accessibles via le lien
            suivant : http://ec.europa.eu/consumers/odr/ à partir du 15 février 2016. Conformément
            aux dispositions du Code de la consommation concernant le règlement amiable des litiges,
            MARGARET adhère au Service du Médiateur du e-commerce de la FEVAD (Fédération du
            e-commerce et de la vente à distance) dont les coordonnées sont les suivantes : 60 rue
            de La Boétie – 75008 PARIS – http://www.mediateurfevad.fr. Après démarche préalable
            écrite des consommateurs vis-à-vis de MARGARET, le Service du Médiateur peut être saisi
            pour tout litige de consommation dont le règlement n’aurait pas abouti. Pour connaître
            les modalités de saisine du Médiateur, cliquez ici. De convention expresse, à défaut
            d’accord amiable, les juridictions du ressort du siège social de MARGARET seront seules
            compétentes pour tout litige ou contestation se rapportant notamment, mais pas
            exclusivement à la formation, l’interprétation, à l’exécution, ou à l’inexécution,
            résiliation des présentes, nonobstant pluralité́ de défendeurs ou appel en garantie, même
            pour les procédures conservatoires, en référé́ ou par requête. En cas de difficulté́
            d'interprétation, la version en langue française des présentes CGU prévaut sur toute
            autre version qui serait traduite dans une autre langue. ARTICLE 14 : ANNULATION ET
            INEFFICACITÉ DES CLAUSES La nullité́ ou l’impossibilité́ de mise en œuvre de l’une des
            quelconques clauses des présentes CGU, si elle ne remet pas en cause notablement
            l’équilibre contractuel, n’affectera que la clause concernée et n’entraînera pas
            l’annulation des présentes CGU. L’Utilisateur s’engage à lire attentivement le contenu
            du présent document qui inclut les Conditions Générales d’Utilisation du Service, et
            accepte expressément et sans réserve toutes les règles et obligations qui y sont
            exposées. ARTICLE 15 DISPOSITIONS DIVERSES 15.1 MARGARET se réserve le droit, à tout
            moment, de modifier ou interrompre l'accessibilité de tout ou partie du Service MARGARET
            et/ou du Site Internet ou des Applications. 15.2 Le fait que MARGARET ne se prévale pas
            à un moment donné de l’une quelconque des dispositions des présentes CGU ne peut être
            interprété comme valant renonciation à se prévaloir ultérieurement de l’une quelconque
            desdites conditions. 15.3 MARGARET se réserve la possibilité de modifier, à tout moment,
            en tout ou partie des CGU. Les Visiteurs et les Organisateurs sont invités à consulter
            régulièrement les présentes CGU afin de prendre connaissance de changements éventuels
            effectués. L'Utilisation du Site par les Visiteurs et les Organisateurs constitue
            l'acceptation par ces derniers des modifications apportées aux CGU. 15.4 Si une partie
            des CGU devait s'avérer illégale, invalide ou inapplicable, pour quelque raison que ce
            soit, les dispositions en question seraient réputées non écrites, sans remettre en cause
            la validité des autres dispositions qui continueront de s'appliquer entre les Visiteurs
            ou les Organisateurs et MARGARET. ARTICLE 16 : DEFINITIONS Chacun des termes mentionnés
            ci-dessus a dans les présentes Conditions Générales d'Utilisation du Service MARGARET
            (ci-après dénommées les " CGU ") la signification suivante : Evénement : désigne toute
            manifestation ouverte au public organisée par l’Organisateur dont le déroulement est
            conforme à la réglementation française ( le site d’accueil est prévu pour la réception
            du public dans les normes de sécurité et/ou la déclaration préalable d’une manifestation
            sur la voie publique a été obtenue en mairie ou en préfecture) et désigne l'ensemble des
            éléments et données (visuelles, textuelles, sonores, photographies, dessins, dates),
            déposé par un Organisateur sous sa responsabilité éditoriale, en vue d’annoncer aux
            Visiteurs l’organisation d’un Evénement à venir et diffusé sur le Site et les
            Applications. Visiteur : désigne tout visiteur, ayant accès au Service MARGARET via le
            Site et les Applications et consultant le Service MARGARET, accessible depuis ces
            différents supports. Organisateur : désigne toute personne morale établie en France
            (inclus les DOM à l'exclusion de Mayotte et des COM), titulaire d’un Compte
            Organisateur, et ayant été invité par un autre Organisateur et ayant déposé et mis en
            ligne un Evénement via le Service MARGARET. Client : désigne tout Organisateur ou
            Visiteur ayant choisi des options payantes via le Service MARGARET. MARGARET : désigne
            la société qui édite et exploite le Site et les Applications : MARGARET, SAS au capital
            de 200000 euros, immatriculée au registre du commerce et des sociétés de Douai sous le
            numéro B 894 554 575, dont le siège social est situé 21 Rue Sadi CARNOT 59191
            LIGNY-EN-CAMBRESIS. Service MARGARET : désigne les services MARGARET mis à la
            disposition des Visiteurs et des Organisateurs sur le Site Internet et les Applications
            tels que décrits à l'article 2 des présentes CGU. Site : désigne le site internet
            exploité par MARGARET accessible principalement depuis l'URL https://margaret.app ou
            https://margaret.fr et permettant aux Visiteurs et aux Organisateurs d'accéder via
            internet au Service MARGARET décrit à l'article 2 des présentes CGU. Applications :
            désigne l’application Android téléchargeable gratuitement sur un téléphone Android
            depuis le Google Play Store, et l’application optimisée pour iPhone et iPad
            téléchargeable depuis l’App Store d’Apple gratuitement sur un iPhone ou un iPad
            disposant a minima, de la version iOS 11 pour iPhone et iPad, permettant aux Visiteurs
            et aux Organisateurs d’accéder au service MARGARET tel que défini à l’article 2 des
            présentes CGU. Compte Personnel : désigne l'espace gratuit, accessible depuis le Site
            Internet et les Applications, que tout Visiteur peut se créer, et à partir duquel il
            peut visualiser ses Evénements, être alerté, enregistrer ses événements ou encore
            apprécier les Evénements. Compte Organisateur : désigne l'espace accessible depuis le
            Site et à partir duquel il peut diffuser, gérer, visualiser ses Evénements. Messagerie :
            désigne le système de réception de messages mis à la disposition des Visiteurs dans le
            cadre du Service MARGARET et permettant la réception d’alertes, d’évaluation et de
            sondage. La Messagerie est uniquement accessible aux Visiteurs disposant d'un Compte
            Personnel. Notifications : désigne les notifications reçues par les Visiteurs dans le
            cadre du Service MARGARET dans les cas définis à l’article 2.2.6. des présentes CGU. Les
            Notifications sont uniquement accessibles aux Visiteurs disposant d’un Compte Personnel
            depuis l’Application ou le Site. Notation : désigne toute évaluation d'un Organisateur
            représentée quantitativement par une note allant de 1 à 5 étoiles affichée sur son
            profil. Le Visiteur peut laisser une note (uniquement après qu’un Evénement ait été
            enregistré dans son agenda et uniquement à l’issue d’un Evénement.
        </p>
        <p class="font-bold">MARGARET . CONTRAT RGPD .</p>

        <p>
            En tant qu’acteur de l’économie numérique, nous sommes particulièrement concernés par la
            protection des données à caractère personnel de nos utilisateurs.
        </p>

        <p>
            Vous trouverez ci-dessous la Politique de protection de la vie privée de MARGARET
            autrement dit l’explication de nos pratiques et de nos engagements vis-à-vis du
            traitement de vos données à caractère personnel, en conformité notamment avec le
            Règlement Général sur la Protection des Données (RGPD). Conscients de l’importance d’une
            information claire et transparente en la matière, nous y avons intégré différents
            tableaux et visuels pour vous aider à mieux comprendre et exercer vos droits. Cette
            politique a été mise à jour pour la dernière fois le 01/06/2021. ARTICLE 1 :
            RESPONSABILITE DU TRAITEMENT DES DONNEES DE L’APPLICATION MARGARET L’application
            MARGARET est éditée et gérée techniquement par la société MARGARET, (donner les détails)
            (ci-après dénommée « MARGARET »). Lorsque vous naviguez sur cette application et
            utilisez ses différentes fonctionnalités, différentes données vous concernant peuvent
            être collectées à des fins, notamment de fourniture du Service, ou encore pour répondre
            à des obligations légales incombant à MARGARET, ainsi que décrit dans la présente
            Politique. En tant qu’éditeur de l’application, MARGARET est le responsable du
            traitement de ces données au sens du RGPD. Cela signifie que nous sommes votre
            interlocuteur pour toute question ou inquiétude relative à la collecte et à
            l’utilisation de vos données sur l’application. Vous pouvez pour ce faire nous écrire à
            l’adresse électronique support@margaret.app Important : Pour le traitement des données
            des Acheteurs dans le cadre des Ventes (paiement, facturation et livraison), la
            responsabilité de ces traitements est partagée entre MARGARET et les Vendeurs concernés,
            dans la mesure où ces derniers (i) contrôlent et dirigent leurs employés, (ii)
            bénéficient de l’action de leurs représentants et (iii) sont les cocontractants des
            Acheteurs dans le cadre des Ventes et en bénéficient financièrement. Cela étant,
            conformément à l’article 26.1 du RGPD, MARGARET et les Vendeurs s’entendent, au titre du
            Contrat RGPD, pour désigner MARGARET comme l’interlocuteur prioritaire des personnes
            concernées pour toute question ou réclamation relative à ces traitements. ARTICLE 2 :
            NATURE DES DONNEES COLLECTEES ET RAISONS DE LA COLLECTE a) Données collectées par le
            serveur d’hébergement de l’application Lorsque vous vous connectez à l’application
            MARGARET, un certain nombre de données sont automatiquement collectées par l’hébergeur
            de l’application concernant votre terminal (smartphone, tablette). Cela inclut notamment
            votre adresse IP. Ces données sont collectées aux fins d’assurer la connexion entre
            votre terminal et les serveurs de l’application, ainsi qu’ultérieurement pour détecter
            des bugs et des tentatives de cyberattaque et à des fins de mesure d’audience. La
            collecte et le traitement de ces données sont ainsi justifiés par l’intérêt légitime de
            MARGARET à fournir une application fonctionnelle, sécurisée et adaptée aux différents
            types de terminaux et navigateurs présents sur le marché, conformément à l’article
            6.1.f) du RGPD. Ces données sont conservées pour la durée de prescription civile
            applicable (5 ans à compter de la date de la requête http) pour les raisons probatoires
            décrites au point i) ci-après. b) Création d’un Compte La création d’un Compte
            conformément à notre CGS nécessite que vous renseignez • Si vous utilisez le Service en
            tant que personne physique : vos nom, prénom(s), mot de passe, adresse de résidence,
            numéro de téléphone, adresse électronique ainsi qu’un mot de passe. • Si vous utilisez
            le Service au nom et pour le compte d’une personne morale : vos nom, prénom(s), adresse
            électronique et numéro de téléphone, ainsi que la raison sociale, l’adresse du siège
            social, le numéro SIRET ou RNA et le numéro de TVA intracommunautaire de la personne
            morale concernée, et enfin un mot de passe. Nous collectons également, de manière
            automatique, la date et l’heure de création de votre Compte, ainsi que l’adresse IP
            utilisée pour ce faire. Ces données seront utilisées pour assurer la création et le
            maintien de votre Compte dans notre base d’utilisateurs, pour éditer les factures
            correspondant à vos Ventes, ainsi que pour échanger avec vous, le cas échéant, en lien
            avec votre utilisation du Service. Elles seront également utilisées pour remplir nos
            obligations de déclarations fiscales, prévues par l’article 242 bis du Code général des
            impôts et l’article 23 L de l’annexe IV de ce code. Enfin, nous avons l’obligation de
            collecter et conserver certaines de ces données au titre du décret n°2011-219 du 25
            février 2011 relatif à la conservation et à la communication des données permettant
            d'identifier toute personne ayant contribué à la création d'un contenu mis en ligne. La
            collecte et le traitement de ces données sont ainsi justifiés à la fois en ce qu’ils
            sont nécessaires à l’exécution d’un contrat conclu à votre demande, à savoir les CGS
            d’MARGARET (article 6.1.b) du RGPD), et pour des obligations légales incombant à
            MARGARET (article 6.1.c) du RGPD). Les données liées à votre Compte sont conservées
            pendant toute la durée de celui-ci (c’est-à-dire jusqu’à ce que vous le supprimiez/nous
            le supprimions en application de nos CGS), puis pour la durée de prescription civile
            applicable (5 ans) pour les raisons probatoires décrites au point i. ci-après. c)
            Souscription d’abonnements ou de services par l’Organisateur Lorsque vous souscrivez un
            Abonnement ou un service payant pour communiquer sur l’application, nous collectons
            l’adresse IP à l’origine du paiement, le motif du paiement, votre identifiant Vendeur,
            les coordonnées de la carte bancaire utilisée pour le paiement, ainsi que le montant, la
            date et l’heure du paiement. Ces données sont nécessaires pour la gestion de notre
            propre facturation et la tenue de notre propre comptabilité. Nous avons également
            l’obligation de collecter et conserver ces données au titre du décret n°2011-219 du 25
            février 2011 relatif à la conservation et à la communication des données permettant
            d'identifier toute personne ayant contribué à la création d'un contenu mis en ligne. La
            collecte et la conservation de ces données sont ainsi justifiées à la fois par leur
            nécessité pour permettre votre utilisation du Service conformément aux CGS (article
            6.1.b) RGPD) et par les obligations légales incombant à MARGARET (article 6.1.c) RGPD).
            Ces données sont conservées pour une durée maximale de dix (10) ans, correspondant à la
            durée de conservation légale des documents comptables en France (article L. 123-22 du
            Code de commerce). Par exception, toutefois, les coordonnées de carte bancaire ne sont
            pas conservées au-delà de quinze (15) mois suivant la date du dernier prélèvement. d)
            Publication d’Evènement et Profil Organisateur Chaque Organisateur est libre de publier
            le contenu qu’il souhaite sur son Profil Organisateur ainsique dans des Annonces. Nous
            collectons automatiquement, en lien avec chacun de ces contenus, les date et heure de sa
            publication et l’adresse IP utilisée pour ce faire. Ces contenus et ces informations
            sont rattachées au Compte du Organisateur concerné et constituent ainsi des données à
            caractère personnel. Nous stockons ces données aux fins de publication du Profil
            Organisateur et des Évènements conformément à nos CGS. Nous avons l’obligation de
            collecter et conserver ces données au titre du décret n°2011-219 du 25 février 2011
            relatif à la conservation et à la communication des données permettant d'identifier
            toute personne ayant contribué à la création d'un contenu mis en ligne. Le traitement de
            ces données est ainsi justifié à la fois en ce qu’il est nécessaire à l’exécution d’un
            contrat conclu à la demande de l’Organisateur concerné, à savoir les CGS de MARGARET
            (article 6.1.b) du RGPD), et pour des obligations légales incombant à MARGARET (article
            6.1.c) du RGPD). Nous conserverons ces données pour la durée de prescription civile
            applicable (5 ans à compter de la suppression du contenu concerné) pour les raisons
            probatoires décrites au point i. ci-après.
        </p>
    </div>
</ion-content>
