<ion-header>
    <ion-toolbar>
        <ion-title>Merci d'accepter les CGU</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col size="12">
                <ion-item lines="none" class="ion-item-clear">
                    <ion-checkbox
                        slot="start"
                        color="primary"
                        [(ngModel)]="cgu"
                        class="ion-text-wrap"
                    >
                        J'accepte les conditions générales de l'application Margaret
                    </ion-checkbox>
                </ion-item>
            </ion-col>
            <ion-col size="12" class="ion-text-center">
                <ion-button fill="clear" (click)="openCGU()">(lire les conditions)</ion-button>
                <ion-button (click)="onAccept()" expand="block" color="primary" [disabled]="!cgu">
                    Valider
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer class="ion-text-center">
    <ion-button (click)="onCancel()" fill="clear" expand="block" color="danger">Refuser</ion-button>
</ion-footer>
