<ion-header>
    <!-- <ion-toolbar
        color="primary"
        style="padding: 0px"
        [style]="
            authService.isAuth
                ? 'background: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url(' +
                  getCover() +
                  ');'
                : ''
        "
    > -->
    <ion-toolbar color="primary" style="padding: 0px">
        <ion-grid>
            <ion-row>
                <ion-col size="2">
                    <div class="flex ion-justify-content-start ion-align-items-center">
                        <img
                            (click)="backToHome()"
                            src="/assets/img/logo_new_baseline.png"
                            class="cursor-pointer"
                        />
                    </div>
                </ion-col>
                <ion-col size="10" class="flex ion-justify-content-end ion-align-items-center">
                    <!-- <img
                        [src]="getCover()"
                        [alt]="authService.currentOrganization.name"
                        *ngIf="authService.isAuth"
                    /> -->
                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-buttons slot="end" *ngIf="!authService.isAuth">
            <ion-button (click)="onAuth($event)" expand="block" fill="clear" size="large">
                Connexion
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
