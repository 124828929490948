import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { SessionService } from 'src/app/services/session.service'

@Component({
    selector: 'app-onboarding-modal',
    templateUrl: './onboarding-modal.component.html',
    styleUrls: ['./onboarding-modal.component.scss']
})
export class OnboardingModalComponent implements OnInit {
    constructor(
        private modalController: ModalController,
        public authSrv: AuthService,
        public sessionSrv: SessionService
    ) {}

    ngOnInit() {}

    onClose() {
        this.modalController.dismiss()
    }
}
