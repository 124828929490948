import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { ImagePreviewService } from 'src/app/services/image-preview.service'

@Component({
    selector: 'app-image-uploader',
    templateUrl: 'image-uploader.component.html'
})
export class ImageUploaderComponent implements OnInit {
    // @Input() files: File[] = []
    @Input() imagePreviews: string[] = []
    @Input() multiple = true
    @Input() image?: string

    @ViewChild('uploadInput') uploadInput: ElementRef

    constructor(public imagePreviewService: ImagePreviewService) {}

    ngOnInit() {
        this.imagePreviewService.currentBase64Logo = undefined
    }

    // async addImage(event: any) {
    //     this.imagePreviewService.imageURI = undefined
    //     if (event.target.files[0].size < 2048000) {
    //         if (!this.multiple) {
    //             this.imagePreviewService.files = []
    //         }

    //         this.files = this.imagePreviewService.addImage(event.currentTarget as HTMLInputElement)
    //     } else {
    //         const alert = await this.alertCtrl.create({
    //             message: `L'image est trop lourde.`,
    //             buttons: [{ text: 'OK' }]
    //         })
    //         await alert.present()
    //     }
    // }

    async onGetPhoto() {
        this.imagePreviewService.onGetPhoto().then(() => {
            this.image = undefined
            this.imagePreviewService.files = []
        })
    }

    getPhoto() {
        if (this.image) {
            return this.image
        } else if (this.imagePreviewService.currentBase64Logo) {
            return this.imagePreviewService.currentBase64Logo
        }
        return '/assets/icon/margaret.svg'
    }
}
