import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class SearchService {
    city: string
    gpsLat: number = null
    gpsLon: number = null

    constructor() {}

    reset() {
        this.city = undefined
        this.gpsLat = undefined
        this.gpsLon = undefined
    }
}
