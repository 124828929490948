import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { environment } from '../shared/environments/environment'
import { UpdateUserData } from '../shared/models/user/update-user-data.model'

@Injectable({ providedIn: 'root' })
export class UserService {
    genders = [
        { id: 1, label: 'Homme' },
        { id: 2, label: 'Femme' },
        { id: 3, label: 'Autre' }
    ]

    constructor(private httpClient: HttpClient) {}

    activate(userUid: string) {
        return this.httpClient.put(`${environment.apiV3Url}/users/activate`, { userUid }).pipe(
            map(data => {
                return data
            })
        )
    }

    getUser(id: string) {
        return this.httpClient.get(`${environment.apiV3Url}/users/${id}`).pipe(
            map(data => {
                return data
            })
        )
    }

    updatePassword(formData: FormData) {
        return this.update(formData)
    }

    updateProfile(formData: FormData) {
        return this.update(formData)
    }

    private update(formData: FormData) {
        return this.httpClient.put<UpdateUserData>(`${environment.apiV3Url}/users`, formData).pipe(
            map(data => {
                return data
            })
        )
    }
}
