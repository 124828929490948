<div
    [ngClass]="{
        relative: true,
        flex: true,
        'cursor-pointer': imagePreviews.length === 0,
        'mb-4': true,
        'justify-center': true,
        'items-center': true,
        'border-2': true,
        'border-solid': true,
        'border-indigo-300': imagePreviews.length === 0,
        'text-indigo-300': true,
        'hover:text-indigo-400': true,
        'hover:border-indigo-400': imagePreviews.length === 0,
        'w-[100%]': true,
        'h-[360px]': true,
        'rounded-[10px]': true
    }"
>
    <img
        class="w-full h-[360px] object-cover rounded-[10px]"
        [src]="getPhoto()"
        alt="Votre photo de profil"
    />
    <!-- <input [hidden]="true" #uploadInput type="file" class="" (change)="addImage($event)" />
    <div
        (click)="imagePreviews.length === 0 ? uploadInput.click() : null"
        [ngClass]="{
            relative: true,
            flex: true,
            'cursor-pointer': imagePreviews.length === 0,
            'mb-4': true,
            'justify-center': true,
            'items-center': true,
            'border-2': true,
            'border-solid': true,
            'border-indigo-300': imagePreviews.length === 0,
            'text-indigo-300': true,
            'hover:text-indigo-400': true,
            'hover:border-indigo-400': imagePreviews.length === 0,
            'h-[360px]': true,
            'rounded-[10px]': true
        }"
    >
        <div class=" font-medium text-2xl text-center" *ngIf="imagePreviews.length === 0">
            Ajouter une image
            <br />
            (2 Mo maximum)
        </div>
        <div
            (click)="uploadInput.click()"
            *ngIf="imagePreviews.length > 0"
            class="absolute cursor-pointer bottom-4 right-4 bg-white py-2 px-4 rounded-3xl text-indigo-400 hover:text-indigo"
        >
            Modifier
        </div>
        <img
            *ngIf="imagePreviews.length > 0"
            class="w-full h-[360px] object-cover rounded-[10px]"
            [src]="imagePreviews[0]"
        />
    </div>
    <div *ngIf="multiple" class="grid grid-cols-3 gap-4">
        <div *ngFor="let img of imagePreviews.slice(1)">
            <img class="w-full h-[260px] object-cover rounded-[10px]" [src]="img" />
        </div>

        <div
            *ngIf="imagePreviews.length >= 1"
            class="flex cursor-pointer mb-4 justify-center items-center border-2 border-solid border-indigo-300 text-indigo-300 hover:text-indigo-400 hover:border-indigo-400 h-[260px] rounded-[10px]"
            (click)="uploadInput.click()"
        >
            Ajouter une image
        </div>
    </div> -->
</div>
<!-- <ion-button (click)="onGetPhoto()" >Changer la photo</ion-button> -->
<ion-badge
    color="light"
    class="cursor-pointer"
    style="position: relative; right: 20px; float: right; top: -52px"
    (click)="onGetPhoto()"
>
    Importer une photo
</ion-badge>
<div class="image-info" style="font-style: italic">
    Images acceptées {{ imagePreviewService.supportedFormats }}. Poids maxi : 10 Mo.
</div>
