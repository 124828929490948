import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
// import { GooglePlaceModule } from 'ngx-google-places-autocomplete'
import { ImageUploaderComponent } from './image-uploader.component'

@NgModule({
    declarations: [ImageUploaderComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule
        // GooglePlaceModule
    ],
    exports: [ImageUploaderComponent]
})
export class ImageUploaderModule {}
