import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
    selector: 'app-legal-use',
    templateUrl: 'legal-use.component.html',
    styleUrls: ['legal-use.component.scss']
})
export class LegalUseComponent {
    constructor(private modalController: ModalController) {}

    onClose() {
        this.modalController.dismiss()
    }
}
