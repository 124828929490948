import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ToastController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { SessionService } from 'src/app/services/session.service'

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
    loginForm: FormGroup

    constructor(
        private authSrv: AuthService,
        private toastCtrl: ToastController,
        private sessionSrv: SessionService
    ) {}

    ngOnInit() {
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email])
        })
    }

    resetPassword() {
        this.authSrv.passwordReset(this.loginForm.value.email).subscribe(async res => {
            const toast = await this.toastCtrl.create({
                message: `Si l'adresse ${this.loginForm.value.email} existe, vous allez recevoir un message dans les prochaines minutes.`,
                duration: 5000,
                color: 'primary'
            })
            toast.present()
        })
    }

    onLogin() {
        this.sessionSrv.authPage = 'omni'
    }
}
