import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.isAuth) {
            req = this.addAuthenticationHeader(req)
        }
        return next.handle(req)
    }

    private addAuthenticationHeader(req: HttpRequest<any>) {
        // const urlArray = req.url.split('//')
        // const domain = urlArray[1].split('.')[0]

        const token = this.authService.getMargaretToken()
        // const headers =
        //     domain === 'dev-backa'
        //         ? req.headers
        //               .set('Authorization', `Bearer ${token}`)
        //               .set('mak', 'nRoVAEvVYUB6W8CoXUjr')
        //         : req.headers.set('Authorization', `Bearer ${token}`)

        const headers = req.headers.set('Authorization', `Bearer ${token}`)
        return req.clone({ headers })
    }
}
