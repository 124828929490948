import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { ToastController } from '@ionic/angular'
import { compareDesc, format, formatISO, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
// import 'hammerjs'
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete'
import { HttpErrorResponse } from '@angular/common/http'
import { ImageCroppedEvent } from 'ngx-image-cropper'
import { AuthService } from 'src/app/services/auth.service'
import { CategoryService } from 'src/app/services/category.service'
import { EventService } from 'src/app/services/event.service'
import { GooglePlaceService } from 'src/app/services/google-place.service'
import { ImagePreviewService } from 'src/app/services/image-preview.service'
import { ToastService } from 'src/app/services/toast.service'
import { UtilsService } from 'src/app/services/utils.service'
import { MGEvent, MGEventStatus } from 'src/app/shared/models/event'
import { Category } from '../../../shared/models/category.model'
import { PlaceInfos } from '../../../shared/models/google-map/place-infos.model'

@Component({
    selector: 'app-event-form',
    templateUrl: 'event-form.component.html',
    styleUrls: ['event-form.component.scss']
})
export class EventFormComponent implements OnInit, OnDestroy {
    @Input() event: MGEvent

    begin = ''
    beginCalendarOpen = false
    beginTime = ''
    categories: Category[] = []
    selectedCategory: Category
    countryISO: string
    end = ''
    endCalendarOpen = false
    endTime = ''
    gps_lat: number
    gps_lng: number
    street: string
    zipcode: string
    loading = false
    action: string
    mode: 'new' | 'edit' = 'new'
    imageError = ''

    eventForm: FormGroup
    options = {
        bounds: undefined,
        componentRestrictions: { country: 'FR' },
        types: [],
        fields: ['address_component', 'formatted_address', 'name', 'geometry'],
        strictBounds: false,
        origin: undefined
    }
    today: string
    minDateTo: string
    placeFormatted: PlaceInfos
    datesConsistency = undefined
    charsCount = 0
    addressName: string
    autocomplete

    imageChangedEvent: any = ''
    imagePreview
    eventPreview: MGEvent

    @ViewChild('placesRef') placesRef: NgxGpAutocompleteDirective

    constructor(
        public authService: AuthService,
        private eventService: EventService,
        public imagePreviewService: ImagePreviewService,
        private router: Router,
        private toastService: ToastService,
        private googlePlaceService: GooglePlaceService,
        public categoryService: CategoryService,
        private toastController: ToastController,
        private utilsSrv: UtilsService
    ) {}

    async onGetPhoto() {
        this.imagePreviewService.onGetPhoto().then(() => {
            this.imagePreview = this.imagePreviewService.currentBase64Logo
            this.eventPreviewUpdate()
        })
    }

    imageCropped(event: ImageCroppedEvent) {
        this.imagePreviewService.currentBase64Logo = event.base64
        this.eventPreviewUpdate()
    }

    async ngOnInit() {
        this.imagePreviewService.currentBase64Logo = undefined
        this.categoryService.getCategories().subscribe(() => {
            this.categories = [...this.categoryService.categories]
            this.categories.shift()
            if (this.event) {
                this.mode = 'edit'
                this.addressName = `${this.event.address.street} ${this.event.address.zipcode} ${this.event.address.city}`
                this.initEvent()
            }
        })
        this.today = new Date().toISOString()
        this.eventForm = new FormGroup({
            organizationId: new FormControl(this.authService.currentOrganization.id, [
                Validators.required
            ]),
            name: new FormControl('', [Validators.required]),
            dateFrom: new FormControl('', [Validators.required]),
            timeFrom: new FormControl('', [Validators.required]),
            dateTo: new FormControl('', [Validators.required]),
            timeTo: new FormControl('', [Validators.required]),
            category: new FormControl('', [Validators.required]),
            addressName: new FormControl('', [Validators.required]),
            description: new FormControl('', [Validators.required])
        })

        this.imagePreviewService.imageURI = undefined
        this.imagePreviewService.files = []

        // if (this.event) {
        //     console.log(`🕹️ > B > this.event =>`, this.event)
        //     this.mode = 'edit'
        //     this.addressName = `${this.event.address.street} ${this.event.address.zipcode} ${this.event.address.city}`
        //     this.initEvent()
        // }
        // this.onDatesChange()

        this.eventForm.valueChanges.subscribe(val => {
            if (this.eventForm.valid) {
                this.eventPreviewUpdate()
            }
        })
    }

    ngOnDestroy(): void {
        this.imagePreviewService.files = []
        this.eventService.currentEventPreview = false
        this.imagePreviewService.currentBase64Logo = undefined
    }

    private initEvent() {
        this.eventForm.reset()
        this.eventForm.patchValue({ organizationId: this.event.organization_id })
        this.eventForm.patchValue({ name: this.event.title })
        this.eventForm.patchValue({ addressName: this.addressName })
        this.eventForm.patchValue({
            dateFrom: format(parseISO(this.event.startAt), 'ddMMyyyy')
        })
        this.eventForm.patchValue({
            timeFrom: format(parseISO(this.event.startAt), 'HHmm')
        })
        this.eventForm.patchValue({
            dateTo: format(parseISO(this.event.finishAt), 'ddMMyyyy')
        })
        this.eventForm.patchValue({
            timeTo: format(parseISO(this.event.finishAt), 'HHmm')
        })
        this.eventForm.patchValue({ category: this.event.category.id })
        this.eventForm.patchValue({ description: this.event.description })

        this.selectedCategory = this.categories.find(cat => cat.id == this.event.category.id)

        this.placeFormatted = {
            name: this.event.title,
            gps_lat: this.event.address.gps_lat,
            gps_lng: this.event.address.gps_lon,
            street: this.event.address.street,
            city: this.event.address.city,
            zipcode: this.event.address.zipcode,
            country: '',
            countryISO: '', //this.event.address.country_iso_code,
            fullAddress: '' //this.event.address.line_address
        }

        this.gps_lat = this.event.address.gps_lat
        this.gps_lng = this.event.address.gps_lon

        this.zipcode = this.event.address.zipcode
        this.street = this.event.address.street
        ;(this.countryISO = ''), //this.event.address.country_iso_code
            (this.begin = this.event.startAt.split('T')[0]) // 2022-03-03
        const zonedDateStart = utcToZonedTime(this.event.startAt, 'Europe/Paris')
        this.beginTime = zonedDateStart.toString().split(' ')[4].slice(0, -3)

        this.end = this.event.finishAt.split('T')[0]

        const zonedDateEnd = utcToZonedTime(this.event.finishAt, 'Europe/Paris')
        this.endTime = zonedDateEnd.toString().split(' ')[4].slice(0, -3)

        this.endTime = this.event.finishAt.split('T')[1].split(':').slice(0, 2).join(':')
        this.gps_lat = this.event.address.gps_lat
        this.gps_lng = this.event.address.gps_lon

        this.imagePreviewService.imageURI = this.event.img
    }

    private eventPreviewUpdate() {
        this.eventService.currentEventPreview = true
        const category = this.categories.find(cat => {
            return cat.id == this.eventForm.value.category
        })
        this.eventPreview = {
            address: {
                city: this.placeFormatted?.city,
                gps_lat: this.placeFormatted?.gps_lat,
                gps_lon: this.placeFormatted?.gps_lng,
                street: this.placeFormatted?.street,
                zipcode: this.placeFormatted?.zipcode
            },
            category: { id: null, label: category.label },
            category_id: this.eventForm.value.category,
            createdAt: null,
            description: this.eventForm.value.description,
            finishAt: this.utilsSrv
                .maskedString2ISODate(this.eventForm.value.dateTo, this.eventForm.value.timeTo)
                .toISOString(),
            id: null,
            img: this.imagePreviewService.currentBase64Logo,
            organization_id: null,
            publication_date: null,
            startAt: formatISO(
                this.utilsSrv.maskedString2ISODate(
                    this.eventForm.value.dateFrom,
                    this.eventForm.value.timeFrom
                )
            ),
            stats: {
                faved: 0,
                seen: 0,
                shared: 0
            },
            status: MGEventStatus.DRAFT,
            title: this.eventForm.value.title,
            uniqueid: '',
            updatedAt: null
        }
    }

    onAddressChange(event: PlaceInfos | Event) {
        if (event instanceof Event) {
            return
        }

        this.eventForm.patchValue({
            addressName: this.addressName
        })

        this.gps_lat = event.gps_lat
        this.gps_lng = event.gps_lng

        this.zipcode = event.zipcode
        this.street = event.street
        this.countryISO = event.countryISO
    }

    async submit(action: 'draft' | 'public') {
        this.action = action

        const status = action === 'draft' ? MGEventStatus.DRAFT : MGEventStatus.PUB

        this.loading = true

        const input = {
            title: this.eventForm.value.name,
            description: this.eventForm.value.description,
            status: status,
            startAt: formatISO(
                this.utilsSrv.maskedString2ISODate(
                    this.eventForm.value.dateFrom,
                    this.eventForm.value.timeFrom
                )
            ),
            finishAt: formatISO(
                this.utilsSrv.maskedString2ISODate(
                    this.eventForm.value.dateTo,
                    this.eventForm.value.timeTo
                )
            ),
            categoryId: Number(this.selectedCategory.id),
            addressStreet: this.placeFormatted.street || '',
            addressGpsLat: this.placeFormatted.gps_lat,
            addressGpsLon: this.placeFormatted.gps_lng,
            addressZipcode: this.placeFormatted.zipcode || '',
            addressCity: this.placeFormatted.city
        }

        const formData = new FormData()

        // TODO: fix when we can upload multiple images.
        // for (const [index, file] of this.imagePreviewService.getFiles().entries()) {
        //   const imageName = `image_${index + 1}`;
        //   formData.append(imageName, file, `${this.form.value.title}_${imageName}`);
        // }

        const eventImg = await this.imagePreviewService.getImageFileFormat()

        // const files = this.imagePreviewService.getFiles()

        if (this.imagePreviewService.imageTooHeavy) {
            this.loading = false
            return
        }

        if (!eventImg && !this.imagePreviewService.imageURI) {
            this.loading = false
            const toast = await this.toastController.create({
                message: `Une image permettra à votre événement de ne pas passer inaperçu.`,
                duration: 3000,
                color: 'danger'
            })
            await toast.present()
            return
        }

        // const image = files[0]

        if (eventImg) {
            formData.append('img', eventImg, 'image')
        }

        // TODO: build a custom method in utils?
        for (const [key, value] of Object.entries(input)) {
            if (value instanceof Array) {
                if (value.length == 0) {
                    formData.append(`${key}[]`, '')
                } else {
                    for (const e of value) {
                        formData.append(`${key}[]`, e)
                    }
                }
                continue
            }
            formData.append(key, value)
        }

        if (this.event) {
            formData.append('id', this.event.id.toString())

            this.eventService.update(formData).subscribe(
                eventData => {
                    // TODO refactoring
                    this.loading = false
                    this.reset()
                    this.toastService.create('Votre événement a bien été mis à jour !', 3000)
                    this.router.navigate(['/dashboard'])
                },
                (error: HttpErrorResponse) => {
                    this.loading = false
                    const message =
                        error.error.statusCode == 409
                            ? `J'ai reniflé un contenu irrespectueux. Merci de le modifier.`
                            : `Une erreur s'est produite. Veuillez ressayer plus tard.`
                    this.toastService.createError(message, 3000)
                }
            )
            return
        }

        this.eventService.create(formData, action).subscribe(
            eventData => {
                // TODO refactoring
                this.loading = false
                this.reset()
                this.toastService.create('Votre événement a bien été créé !', 3000)
                this.router.navigate(['/dashboard'])
            },
            response => {
                this.loading = false
                this.eventService.handleCreateOrUpdateError(response)
            }
        )
    }

    reset() {
        this.eventForm.reset()
        this.placeFormatted = undefined

        this.begin = ''
        this.beginCalendarOpen = false
        this.beginTime = ''
        this.end = ''
        this.endCalendarOpen = false
        this.endTime = ''
        this.gps_lat = null
        this.gps_lng = null

        this.zipcode = ''
        this.street = ''
        this.countryISO = ''
        this.imagePreviewService.files = []
    }

    async handleAddressChange(event: any) {
        this.placeFormatted = this.googlePlaceService.getInfos(
            event,
            document.getElementById('autocomplete') as HTMLInputElement
        )
        if (!this.placeFormatted.city) {
            const toast = await this.toastController.create({
                message: `Une commune est obligatoire dans l'adresse.`,
                duration: 5000,
                color: 'danger'
            })
            await toast.present()
            return
        }
        if (this.eventForm.valid) {
            this.eventPreviewUpdate()
        }
    }

    onDatesChange() {
        const today = new Date()
        const from: Date = this.utilsSrv.maskedString2ISODate(
            this.eventForm.value.dateFrom,
            this.eventForm.value.timeFrom
        )
        const to: Date = this.utilsSrv.maskedString2ISODate(
            this.eventForm.value.dateTo,
            this.eventForm.value.timeTo
        )
        this.datesConsistency = compareDesc(from, to)
        if (this.datesConsistency != -1) {
            this.datesConsistency = compareDesc(today, to)
        }
    }

    descriptionChange() {
        this.charsCount = this.eventForm.value.description
            ? this.eventForm.value.description.length
            : 0
    }

    // onOrganizationChange(event) {
    //     this.authService.currentOrganization = this.authService.currentUserOrganizations.find(
    //         org => org.id == event.detail.value
    //     )
    // }

    onCategoryChange(event) {
        this.selectedCategory = this.categories.find(cat => cat.id == event.detail.value)
    }

    cancel() {
        this.router.navigate(['/dashboard'])
    }
}
