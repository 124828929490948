import { Injectable } from '@angular/core'
import jwt_decode from 'jwt-decode'
import { AccessTokenData } from '../shared/models/access-token-data.model'
import { CurrentUser } from '../shared/models/auth/current-user.model'

@Injectable({ providedIn: 'root' })
export class JwtService {
    constructor() {}

    decode(accessToken): AccessTokenData {
        let accessTokenData = undefined
        try {
            accessTokenData = jwt_decode(accessToken) as AccessTokenData
        } catch (error) {
            console.error(`🔥 > JwtService > decode > error`, error)
        }

        return accessTokenData
    }

    clearAuthData() {
        localStorage.removeItem('token')
        localStorage.removeItem('expiration')
        localStorage.removeItem('user')
    }

    getAuthData(): { token: string; expirationDate: Date; user: CurrentUser } {
        const token = localStorage.getItem('token')
        const expiration = localStorage.getItem('expiration')
        const userStr = localStorage.getItem('user')

        if (!token || !expiration || !userStr) {
            return
        }

        return {
            token,
            expirationDate: new Date(expiration),
            user: JSON.parse(userStr)
        }
    }

    getExpirationDate(exp: number): Date {
        const expirationDate = new Date(new Date().getTime() + exp)
        return expirationDate
    }

    saveAuthData(token: string, expirationDate: Date, user: CurrentUser) {
        localStorage.setItem('token', token)
        localStorage.setItem('expiration', expirationDate.toISOString())
        this.saveCurrentUser(user)
    }

    saveCurrentUser(currentUser: CurrentUser) {
        localStorage.setItem('user', JSON.stringify(currentUser))
    }
}
