<ion-app>
    <app-header-lg *ngIf="!sessionSrv.crossLogout"></app-header-lg>

    <ion-split-pane
        when="xs"
        contentId="main"
        *ngIf="authService.isAuth"
        style="max-width: 1248px; margin: auto"
    >
        <ion-menu contentId="main">
            <ion-content class="ion-padding side-menu">
                <app-side-menu></app-side-menu>
            </ion-content>
        </ion-menu>

        <div class="ion-page" id="main">
            <ion-content class="ion-padding">
                <ion-router-outlet id="main" class="mt-5 mb-10"></ion-router-outlet>
            </ion-content>
        </div>
    </ion-split-pane>

    <ion-router-outlet
        id="main"
        class="mt-5 mb-10"
        *ngIf="!authService.isAuth"
        style="max-width: 1248px; margin: auto"
    ></ion-router-outlet>
    <app-footer *ngIf="!sessionSrv.crossLogout"></app-footer>
</ion-app>
