import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete'
import { DatePipe, registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import { LOCALE_ID, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { GoogleMapsModule } from '@angular/google-maps'
import { BrowserModule } from '@angular/platform-browser'
import { PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { AppComponent } from './app.component'
import { FooterModule } from './core/components/footer/footer.module'
import { HeaderModule } from './core/components/header/header.module'
import { SideMenuModule } from './core/components/side-menu/side-menu.module'
import { IsAuthGuard } from './guards/is-auth.guard'
import { StripeGuard } from './guards/stripe.guard'
import { TokenInterceptor } from './interceptors/token.interceptor'

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null

const routes: Routes = [
    {
        path: 'signup',
        loadChildren: () => import('./core/pages/signup/signup.module').then(m => m.SignupModule)
    },
    {
        path: 'logout',
        loadChildren: () =>
            import('./core/pages/auth/logout/logout.module').then(m => m.LogoutModule)
    },
    {
        path: 'users/:id/activate',
        title: 'Activation',
        loadChildren: () =>
            import('./core/pages/activation/activation.page.module').then(
                m => m.ActivationPageModule
            )
    },
    {
        path: 'password',
        canActivate: [StripeGuard],
        title: 'Mot de passe oublié',
        loadChildren: () =>
            import('./core/pages/password-reset/password-reset.module').then(
                m => m.PasswordResetModule
            )
    },
    {
        path: 'reset-password/:token',
        title: 'Nouveau mot de passe',
        loadChildren: () =>
            import('./core/pages/password-new/password-new.module').then(m => m.PasswordNewModule)
    },
    {
        path: 'home',
        canActivate: [StripeGuard],
        title: 'Accueil',
        loadChildren: () => import('./core/pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'home/:token/:onboarding',
        canActivate: [StripeGuard],
        title: 'Accueil',
        loadChildren: () => import('./core/pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'auth',
        canActivate: [StripeGuard],
        title: 'Connexion',
        loadChildren: () => import('./core/pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'valeurs',
        canActivate: [StripeGuard],
        title: 'Nos valeurs',
        loadChildren: () =>
            import('./core/pages/valeurs/valeurs.module').then(m => m.ValeursPageModule)
    },
    {
        path: 'legal',
        canActivate: [StripeGuard],
        title: 'Mentions légales',
        loadChildren: () => import('./core/pages/legal/legal.module').then(m => m.LegalPageModule)
    },
    {
        path: 'cgu',
        canActivate: [StripeGuard],
        title: "Conditions générales d'utilisation",
        loadChildren: () => import('./core/pages/cgu/cgu.module').then(m => m.CguPageModule)
    },
    {
        path: 'confidentialité',
        canActivate: [StripeGuard],
        title: 'Confidentialité',
        loadChildren: () =>
            import('./core/pages/confidentiality/confidentiality.module').then(
                m => m.ConfidendialityPageModule
            )
    },
    {
        path: 'subscription',
        canActivate: [StripeGuard],
        title: 'Abonnement',
        loadChildren: () =>
            import('./core/pages/subscription/subscription.module').then(m => m.SubscriptionModule)
    },
    {
        path: 'subscription/management',
        canActivate: [StripeGuard],
        title: "Gestion de l'abonnement",
        loadChildren: () =>
            import('./core/pages/subscription-management/subscription-management.module').then(
                m => m.SubscriptionManagementModule
            )
    },
    {
        path: 'subscription/success',
        title: 'Abonnement réussi',
        loadChildren: () =>
            import('./core/pages/payment-success/payment-success.module').then(
                m => m.PaymentSuccessModule
            )
    },
    {
        path: 'subscription/failure',
        title: "L'abonnement a échoué",
        loadChildren: () =>
            import('./core/pages/payment-failure/payment-failure.module').then(
                m => m.PaymentFailureModule
            )
    },
    {
        path: 'organizations/new',
        canActivate: [IsAuthGuard, StripeGuard],
        title: 'Nouvelle organisation',
        loadChildren: () =>
            import('./core/pages/organization-edit/organization-edit.module').then(
                m => m.OrganizationEditModule
            )
    },
    {
        path: 'organizations/margaret',
        canActivate: [StripeGuard],
        title: 'Margaret',
        loadChildren: () =>
            import('./core/pages/organization-margaret/organization-margaret.module').then(
                m => m.OrganizationMargaretModule
            )
    },
    // {
    //     path: 'organizations/:id',
    //     loadChildren: () =>
    //         import('./core/pages/organization-detail/organization-detail.module').then(
    //             m => m.OrganizationDetailModule
    //         )
    // },
    // {
    //     path: 'search-mobile',
    //     loadChildren: () =>
    //         import('../../../trash/search-mobile/search-mobile.module').then(
    //             m => m.SearchMobileModule
    //         )
    // },
    // {
    //     path: 'events',
    //     loadChildren: () =>
    //         import('./core/pages/event-list/event-list.module').then(m => m.EventListModule)
    // },
    {
        path: 'events',
        canActivate: [IsAuthGuard, /*FreeLimitReachedGuard,*/ StripeGuard],
        title: 'Événements',
        loadChildren: () => import('./core/pages/event/event.module').then(m => m.EventModule)
    },
    {
        path: 'events/:id/edit',
        canActivate: [StripeGuard],
        title: "Édition de l'évènement",
        loadChildren: () => import('./core/pages/event/event.module').then(m => m.EventModule)
    },
    {
        path: 'profile/edit',
        canActivate: [IsAuthGuard, StripeGuard],
        title: 'Édition du profil',
        loadChildren: () =>
            import('./core/pages/profile-edit/profile-edit.module').then(m => m.ProfileEditModule)
    },
    {
        path: 'dashboard',
        canActivate: [IsAuthGuard, StripeGuard],
        title: 'Tableau de bord',
        loadChildren: () =>
            import('./core/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'dashboard/devices',
        canActivate: [IsAuthGuard, StripeGuard],
        title: 'Appareils connectés',
        loadChildren: () => import('./core/pages/devices/devices.module').then(m => m.DevicesModule)
    },
    {
        path: 'dashboard/members',
        canActivate: [IsAuthGuard, StripeGuard],
        title: 'Gestion des membres',
        loadChildren: () => import('./core/pages/members/members.module').then(m => m.MembersModule)
    },
    {
        path: 'dashboard/:token/:onboarding',
        canActivate: [StripeGuard],
        title: 'Tableau de bord',
        loadChildren: () =>
            import('./core/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'organizations/:id/edit',
        canActivate: [IsAuthGuard, StripeGuard],
        title: `Édition de l'organisation`,
        loadChildren: () =>
            import('./core/pages/organization-edit/organization-edit.module').then(
                m => m.OrganizationEditModule
            )
    },
    {
        path: 'organizations/edit',
        canActivate: [IsAuthGuard, StripeGuard],
        title: `Édition de l'organisation`,
        loadChildren: () =>
            import('./core/pages/organization-edit/organization-edit.module').then(
                m => m.OrganizationEditModule
            )
    },
    { path: '**', redirectTo: 'home' }
]

registerLocaleData(localeFr, 'fr')

@NgModule({
    declarations: [AppComponent],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        DatePipe
    ],
    bootstrap: [AppComponent],
    imports: [
        NgxGpAutocompleteModule.forRoot({
            loaderOptions: {
                apiKey: 'AIzaSyCvggQ2UGkRUP56wAqijAJTuOK0uUgm9u8',
                libraries: ['places']
            }
        }),
        BrowserModule,
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules
        }),
        IonicModule.forRoot({ backButtonText: '', swipeBackEnabled: false }),
        HttpClientModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        NgxMaskModule.forRoot(),
        HeaderModule,
        FooterModule,
        SideMenuModule
    ]
})
export class AppModule {}
