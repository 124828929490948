<ion-list lines="none">
    <div *ngIf="authSrv.isAuth">
        <ion-item button detail="false" (click)="backToHome()">
            <ion-icon slot="end" name="search"></ion-icon>
            <ion-label>Margaret</ion-label>
        </ion-item>
        <ion-item detail="false" *ngIf="authSrv.currentOrganization" routerLink="/dashboard">
            <ion-label>
                <ion-text color="orange">Tableau de bord</ion-text>
            </ion-label>
        </ion-item>
        <ion-item detail="false" routerLink="/profile/edit">
            <ion-label>Mon profil</ion-label>
        </ion-item>
        <ion-item button detail="false" (click)="onAlerts()">
            <ion-label>Mes alertes</ion-label>
        </ion-item>
        <ion-item button detail="false" (click)="onFavorites()">
            <ion-label>Mes favoris</ion-label>
        </ion-item>
        <!-- <ion-item button detail="false" (click)="logout()">
            <ion-label>Déconnexion</ion-label>
        </ion-item> -->
    </div>
</ion-list>
