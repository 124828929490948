<ion-grid>
    <ion-row>
        <ion-col size="6">
            <h2>{{ mode == 'edit' ? 'Editer' : 'Créer' }} votre événement</h2>
        </ion-col>
        <ion-col size="6">
            <ion-button
                (click)="cancel()"
                expand="block"
                fill="clear"
                [mgButtonState]="'dashboard'"
            >
                Annuler
            </ion-button>
        </ion-col>
    </ion-row>

    <form [formGroup]="eventForm">
        <!-- <ion-row>
            <ion-col size="12">
                <ion-select
                    formControlName="organizationId"
                    interface="popover"
                    multiple="false"
                    placeholder="Choisissez une de vos organisations *"
                    aria-label="Choisissez une de vos organisations *"
                    (ionChange)="onOrganizationChange($event)"
                >
                    <ion-select-option
                        *ngFor="let organization of authService.currentUserOrganizations"
                        [value]="organization.id"
                    >
                        {{ organization.name }}
                    </ion-select-option>
                </ion-select>
            </ion-col>
        </ion-row> -->
        <ion-row>
            <ion-col size="12">
                <input required formControlName="name" placeholder="Titre de votre événement *" />
                <div
                    *ngIf="
                        eventForm.get('name').invalid &&
                        (eventForm.get('name').dirty || eventForm.get('name').touched)
                    "
                >
                    <ion-text color="danger">Le titre est obligatoire.</ion-text>
                </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="6" sizeLg="6" sizeXs="12">
                <input
                    type="text"
                    mask="d0/M0/0000"
                    [leadZeroDateTime]="true"
                    formControlName="dateFrom"
                    placeholder="Date de début *"
                    (input)="onDatesChange()"
                />
                <input
                    type="text"
                    mask="Hh:m0"
                    [leadZeroDateTime]="true"
                    formControlName="timeFrom"
                    placeholder="Heure de début *"
                    (input)="onDatesChange()"
                />
            </ion-col>
            <ion-col size="6" sizeLg="6" sizeXs="12">
                <input
                    type="text"
                    mask="d0/M0/0000"
                    [leadZeroDateTime]="true"
                    formControlName="dateTo"
                    placeholder="Date de fin *"
                    (input)="onDatesChange()"
                />
                <input
                    type="text"
                    mask="Hh:m0"
                    [leadZeroDateTime]="true"
                    formControlName="timeTo"
                    placeholder="Heure de fin *"
                    (input)="onDatesChange()"
                />
            </ion-col>
        </ion-row>
        <ion-row *ngIf="datesConsistency == -1">
            <ion-col size="12">
                <ion-text color="danger">Des dates cohérentes sont obligatoires.</ion-text>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <ion-select
                    formControlName="category"
                    interface="popover"
                    placeholder="Veuillez choisir une catégorie *"
                    aria-label="Veuillez choisir une catégorie *"
                    (ionChange)="onCategoryChange($event)"
                >
                    <ion-select-option [value]="category.id" *ngFor="let category of categories">
                        {{ category.label }}
                    </ion-select-option>
                </ion-select>
                <div
                    *ngIf="
                        eventForm.get('category').invalid &&
                        (eventForm.get('category').dirty || eventForm.get('category').touched)
                    "
                >
                    <ion-text color="danger">La catégorie est obligatoire.</ion-text>
                </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <input
                    id="autocomplete"
                    ngx-gp-autocomplete
                    [options]="options"
                    placeholder="Adresse de l'évènement *"
                    (onAddressChange)="handleAddressChange($event)"
                    formControlName="addressName"
                />
                <div
                    *ngIf="
                        eventForm.get('addressName').invalid &&
                        (eventForm.get('addressName').dirty || eventForm.get('addressName').touched)
                    "
                >
                    <ion-text color="danger">L'adresse est obligatoire.</ion-text>
                </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <ion-textarea
                    rows="10"
                    formControlName="description"
                    placeholder="Description de l'évènement *"
                    (keydown)="descriptionChange()"
                ></ion-textarea>
                <ion-text color="medium">{{ charsCount }} / 2500</ion-text>
                <div
                    *ngIf="
                        eventForm.get('description').invalid &&
                        (eventForm.get('description').dirty || eventForm.get('description').touched)
                    "
                >
                    <ion-text color="danger">La description est obligatoire.</ion-text>
                </div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="eventForm.valid">
            <ion-col size="6" sizeLg="6" sizeXs="12">
                <h2>Photo de l'évènement *</h2>
                <ion-button (click)="onGetPhoto()" expand="block" class="ion-text-wrap">
                    Importer une photo pour avoir un aperçu de votre événement
                </ion-button>
                <image-cropper
                    [maintainAspectRatio]="true"
                    [aspectRatio]="1.78"
                    format="png"
                    [imageBase64]="imagePreview"
                    (imageCropped)="imageCropped($event)"
                    *ngIf="imagePreview"
                ></image-cropper>
            </ion-col>
            <ion-col size="6" sizeLg="6" sizeXs="12">
                <h1 *ngIf="eventPreview && imagePreviewService.currentBase64Logo">Aperçu</h1>
                <app-card-event-home
                    [event]="eventPreview"
                    *ngIf="eventPreview && imagePreviewService.currentBase64Logo"
                ></app-card-event-home>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="mode == 'edit' && !imagePreview">
            <ion-col size="12">
                <ion-img [src]="event.img"></ion-img>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!loading">
            <ion-col size="6">
                <ion-button
                    expand="block"
                    color="secondary"
                    [disabled]="!eventForm.valid || datesConsistency == -1 || !placeFormatted.city"
                    (click)="submit('draft')"
                >
                    Brouillon (visible uniquement par vous)
                </ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button
                    expand="block"
                    color="primary"
                    [disabled]="!eventForm.valid || datesConsistency == -1 || !placeFormatted.city"
                    (click)="submit('public')"
                >
                    Publier
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="loading">
            <ion-col size="12">
                <ion-button expand="block" color="secondary" [disabled]="true">
                    Traitement en cours...
                </ion-button>
            </ion-col>
        </ion-row>
    </form>
</ion-grid>
