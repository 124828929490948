import { Directive, HostListener, Input } from '@angular/core'
import { SessionService } from 'src/app/services/session.service'

@Directive({
    selector: '[mgButtonState]'
})
export class ButtonStateDirective {
    @Input() mgButtonState: string

    constructor(private sessionSrv: SessionService) {}

    @HostListener('click', ['$event']) onClick($event) {
        this.sessionSrv.currentButton = this.mgButtonState
    }
}
