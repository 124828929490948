import { Injectable } from '@angular/core';

interface ReadableDayOption {
  size: 'short' | 'full',
  locale: string;
}

interface ReadableDateOptions {
  langage?: 'fr' | 'en';
  length?: 'short' | 'full';
  style?: 'numerical' | 'written' | 'airbnb';
  withTime?: boolean;
}
@Injectable({ providedIn: 'root' })
export class DateUtil {
  MONTHS = {
    fr: {
      short: [
        'jan',
        'fév',
        'mar',
        'avr',
        'mai',
        'jui',
        'jui',
        'aou',
        'sep',
        'oct',
        'nov',
        'dec',
      ],
      full: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
    },
  };


  DAYS = {
    fr: {
      short: [
        'dim',
        'lun',
        'mar',
        'mer',
        'jeu',
        'ven',
        'sam',
      ],
      full: [
        'dimanche',
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
      ]
    }
  }

  constructor() {}

  readableDate(
    date: Date,
    options: ReadableDateOptions = { langage: 'fr', length: 'short', style: 'written', withTime: false }
  ): string {

    let time = ''

    if (options.withTime) {
      const separator = options.style === 'numerical' ? ':' : 'h'
      const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      time = ` ${date.getHours()}${separator}${minutes}`;
    }

    if (date.toString() === 'Invalid Date') {
      return '';
    }

    if (!date) {
      return '';
    }

    if (options.style === 'airbnb') {
      const [year, month, day ] = date.toISOString().split('T')[0].split('-');
      if (year === new Date().getFullYear().toString()) {
        return `${day}/${month}`;
      }
      return `${day}/${month}/${year}`;
    }

    if (options.style === 'numerical' && options.langage === 'fr') {
      return `${date.toISOString().split('T')[0].replace(/-/g, '/').split('/').reverse().join('/')}${time}`
    }

    if (options.style === 'numerical') {
      return `${date.toISOString().split('T')[0].replace(/-/g, '/')}${time}`;
    }

    const dayOfMonth = date.getDate();
    const monthOfYear = date.getMonth();
    const year = date.getFullYear();

    return `${dayOfMonth} ${this.MONTHS[options.langage][options.length][monthOfYear]} ${year}${time}`;
  }


  readableDay(date: Date, options: ReadableDayOption) {
    if (!Object.keys(this.DAYS).includes(options.locale)) {
      throw new Error(`locale ${options.locale} is not referenced`)
    }

    return this.DAYS[options.locale][options.size][date.getDay()]
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    )
  }

  isPast(date: Date, withTime = false): boolean {
    const refDate = withTime ? new Date() : new Date().setHours(0,0,0);
    return date.getTime() < (refDate);
  }

  isSameWeek(date: Date): boolean {
    return date.getTime() > (new Date().setHours(0,0,0) - (1000*60*60*24*6));
  }
}
