export enum MGEventStatus {
    DRAFT = 'DRAFT',
    PUB = 'PUB',
    CANCELED = 'CANCELED',
    DELETED = 'DELETED'
}

export interface MGEvent {
    address: {
        city: string
        gps_lat: number
        gps_lon: number
        street: string
        zipcode: string
    }
    category: {
        id: number
        label: string
    }
    category_id: number
    createdAt: string
    description: string
    finishAt: string
    id: number
    img: string
    organization_id: number
    publication_date: string
    startAt: string
    stats: {
        faved: number
        seen: number
        shared: number
    }
    status: MGEventStatus
    title: string
    uniqueid: string
    updatedAt: string
}
