import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../shared/environments/environment'
import { MGOrganization, MGSOrganizationtats } from '../shared/models/organization'
import { MGOrganizationMember } from '../shared/models/organization-member'
import { AuthService } from './auth.service'

export interface InviteUserInput {
    mail: string
    firstname: string
    lastname: string
    phone: string
    role: 'RESPONSABLE' | 'REDACTOR'
    organizationUid: string
}

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    MARGARET_ORG_ID = 'b9bb64a6c86681ad5887b76d5df18bf4dff3899b'

    currentUserOrganizations: MGOrganization[]
    currentEditedOrganization: MGOrganization

    constructor(private httpClient: HttpClient, private authService: AuthService) {}

    createOrganization(formData: FormData): Observable<MGOrganization> {
        return this.httpClient
            .post<MGOrganization>(`${environment.advertisersApiUrl}/organizations`, formData)
            .pipe(
                map(newOrganization => {
                    // CHECK: the api doesn't return the stats
                    newOrganization.stats = {
                        seen: 0,
                        subscribed: 0
                    }
                    // CHECK: the api doesn't return the role
                    newOrganization.role = 'RESPONSABLE'

                    this.authService.currentOrganizationEvents = []
                    this.authService.currentUserOrganizations.push(newOrganization)
                    this.authService.currentOrganization =
                        this.authService.currentUserOrganizations[
                            this.authService.currentUserOrganizations.length - 1
                        ]
                    return newOrganization
                })
            )
    }

    destroyOrganization(id: number) {
        return this.httpClient.delete(`${environment.advertisersApiUrl}/organizations/${id}`).pipe(
            map(res => {
                const idx = this.authService.currentUserOrganizations.findIndex(organization => {
                    return organization.id === id
                })
                this.authService.currentUserOrganizations.splice(idx, 1)
                if (this.authService.currentUserOrganizations.length > 0) {
                    this.authService.currentOrganization =
                        this.authService.currentUserOrganizations[0]
                }
                return res
            })
        )
    }

    getOrganization(organizationId: string | number): Observable<MGOrganization> {
        return this.httpClient
            .get<MGOrganization>(`${environment.advertisersApiUrl}/organizations/${organizationId}`)
            .pipe(
                map(organization => {
                    return organization
                })
            )
    }

    getOrganizationLG(id: string | number): Observable<MGOrganization> {
        return this.httpClient
            .get<MGOrganization>(`${environment.advertisersApiUrl}/organizations/${id}`)
            .pipe(
                map(organization => {
                    return organization
                })
            )
    }

    getOrganizations() {
        return this.httpClient.get(`${environment.advertisersApiUrl}/organizations`).pipe(
            map((organizations: any) => {
                this.authService.currentUserOrganizations = organizations
                return organizations
            })
        )
    }

    // TODO: REMOVE?
    getChildren(organizationId: string): Observable<any> {
        return this.httpClient.get(
            `${environment.apiV3Url}/organizations/${organizationId}/children`
        )
    }

    updateMemberRole(organizationId: number, user) {
        return this.httpClient
            .put(`${environment.advertisersApiUrl}/organizations/${organizationId}/members`, user)
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    removeMember(organizationId: number, memberId: string) {
        return this.httpClient
            .delete(
                `${environment.advertisersApiUrl}/organizations/${organizationId}/members/${memberId}`
            )
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    inviteMember(organizationId, member) {
        return this.httpClient
            .post<MGOrganizationMember>(
                `${environment.advertisersApiUrl}/organizations/${organizationId}/members`,
                member
            )
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    updateOrganization(formData: FormData, stats: MGSOrganizationtats): Observable<MGOrganization> {
        return this.httpClient
            .put<MGOrganization>(`${environment.advertisersApiUrl}/organizations`, formData)
            .pipe(
                map(organizationUpdated => {
                    const idx = this.authService.currentUserOrganizations.findIndex(
                        organization => {
                            return organization.id === organizationUpdated.id
                        }
                    )
                    organizationUpdated.stats = stats // the API doesn't return the stats
                    this.authService.currentUserOrganizations[idx] = organizationUpdated
                    this.authService.currentOrganization = organizationUpdated
                    return organizationUpdated
                })
            )
    }

    subscribe(organizationUid: string) {
        return this.httpClient
            .post(`${environment.apiV3Url}/users/subscribe`, {
                orgaUid: organizationUid
            })
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    prefill(identifier) {
        return this.httpClient
            .get(`${environment.apiV3Url}/organizations/prefill?id=${identifier}`)
            .pipe(
                map(data => {
                    return data
                })
            )
    }
}
