import { Injectable } from '@angular/core'
import { CalendarOptions, GoogleCalendar, OutlookCalendar } from 'datebook'

@Injectable({
    providedIn: 'root'
})
export class CalendarsService {
    constructor() {}

    addToCalendar(calendar: string, options: CalendarOptions) {
        // const options: CalendarOptions = {
        //     title: 'Happy Hour',
        //     location: 'The Bar, New York, NY',
        //     description: "Let's blow off some steam with a tall cold one!",
        //     start: new Date('2022-07-08T19:00:00'),
        //     end: new Date('2022-07-08T23:30:00'),
        //     recurrence: {
        //         frequency: 'WEEKLY',
        //         interval: 2
        //     }
        // }

        let render: string = undefined

        switch (calendar) {
            case 'google':
                const googleCalendar = new GoogleCalendar(options)
                render = googleCalendar.render()
                break

            case 'outlook':
                const outlookCalendar = new OutlookCalendar(options)
                render = outlookCalendar.render()
                break

            default:
                break
        }

        render ? window.open(render, '_blank') : null
    }
}
