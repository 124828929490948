import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    searchBarKingSize: boolean
    authPage: string
    askingCode: boolean
    tempUserBeforeActivation: any
    changePasswordJwt: string
    showAuthModal: boolean
    onboarding: boolean
    currentButton: string
    premiumFunctionality = 'Cette fonctionnalité est réservée aux membres Premium (prochainement).'
    crossLogout: boolean

    constructor() {
        this.searchBarKingSize = false
        this.authPage = 'omni'
        this.askingCode = false
        this.showAuthModal = false
        this.onboarding = false
        this.currentButton = 'dashboard'
        this.crossLogout = false
    }
}
