<ion-list lines="none">
    <ion-item
        button
        *ngFor="let organization of authSrv.currentUserOrganizations"
        (click)="onChangeOrganization(organization)"
        [mgButtonState]="'dashboard'"
    >
        <ion-avatar slot="start" class="h-16 w-16">
            <img [src]="organization.logo || '/assets/icon/margaret.svg'" class="h-16 w-16" />
        </ion-avatar>
        <ion-label>{{ organization.name }}</ion-label>
    </ion-item>
    <ion-item button (click)="newOrganization()">
        <ion-icon slot="start" name="add-circle"></ion-icon>
        <ion-label>Ajouter une organisation</ion-label>
    </ion-item>
</ion-list>
