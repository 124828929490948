import { Component, Input, OnInit } from '@angular/core';
import { DateUtil } from 'src/app/utils/date.util';

@Component({
  selector: 'app-calendar-date',
  templateUrl: 'calendar-date.component.html',
  styleUrls: ['calendar-date.component.scss']
})

export class CalendarDateComponent implements OnInit {
  private _date: Date;

  @Input() date: Date | string;
  @Input() size: 'normal' | 'small' | 'xs' = 'normal';

  constructor(private dateUtil: DateUtil) { }

  ngOnInit() {
    if (typeof this.date == 'string') {
      this._date = new Date(this.date)
      return;
    }

    this._date = this.date;
  }

  get isSmall() {
    return this.size === 'small';
  }

  day() {
    return this._date.toISOString().split('T')[0].split('-')[2]
  }

  month() {
    return this.dateUtil.MONTHS.fr.short[this._date.getMonth()].toUpperCase();
  }


}