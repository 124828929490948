import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { HeaderMenuPopoverLgComponent } from './header-menu-popover-lg.component'

@NgModule({
    declarations: [HeaderMenuPopoverLgComponent],
    imports: [CommonModule, IonicModule, RouterModule],
    exports: [HeaderMenuPopoverLgComponent]
})
export class HeaderMenuPopoverLgModule {}
