import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController, PopoverController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { SessionService } from 'src/app/services/session.service'
import { environment } from 'src/app/shared/environments/environment'
import { AuthComponent } from '../../pages/auth/auth.component'
import { HeaderMenuPopoverLgComponent } from '../header-menu-popover-lg/header-menu-popover-lg.component'

@Component({
    selector: 'app-header-lg',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    constructor(
        public authService: AuthService,
        public router: Router,
        private popoverController: PopoverController,
        public sessionSrv: SessionService,
        private modalController: ModalController
    ) {}

    ngOnInit() {}

    async onAuth(event) {
        this.authService.isAuth ? this.presentPopover(event) : await this.presentModal()
    }

    async presentPopover(ev: any) {
        const popover = await this.popoverController.create({
            component: HeaderMenuPopoverLgComponent,
            dismissOnSelect: true,
            event: ev,
            translucent: true,
            cssClass: !this.authService.isAuth ? 'medium-popover-menu-small' : ''
        })
        popover.shadowRoot.lastChild.lastChild['style'].cssText =
            'border-radius: 20px 0px 20px 20px !important;'
        await popover.present()
    }

    private async presentModal() {
        this.sessionSrv.onboarding = false
        this.sessionSrv.authPage = 'omni'
        const modal = await this.modalController.create({
            component: AuthComponent,
            cssClass: 'full-popover',
            canDismiss: true
        })
        await modal.present()
    }

    backToHome() {
        this.sessionSrv.onboarding = false
        // window.open(environment.shareRootUrl, '_self')
        window.open(`${environment.shareRootUrl}/home/${this.authService.token}`, '_self')
    }

    getCover() {
        return this.authService.currentOrganization?.cover != null
            ? this.authService.currentOrganization.cover
            : 'assets/banner.png'
    }
}
