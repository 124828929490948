<ion-header>
    <ion-toolbar color="primary">
        <ion-title>Publier un événement</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onClose()">Annuler</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid *ngIf="authSrv.isAuth">
        <ion-row *ngIf="authSrv.currentOrganization != undefined">
            <ion-col sizeXs="12" sizeLg="10" offsetXs="0" offsetLg="1">
                <app-event-form></app-event-form>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
