import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { OnboardingModalModule } from '../../pages/onboarding-modal/onboarding-modal.module'
import { HeaderMenuPopoverLgModule } from '../header-menu-popover-lg/header-menu-popover.module'
import { HeaderComponent } from './header.component'

@NgModule({
    declarations: [HeaderComponent],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        HeaderMenuPopoverLgModule,
        OnboardingModalModule
    ],
    exports: [HeaderComponent]
})
export class HeaderModule {}
