import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { DirectivesModule } from 'src/app/shared/directives/directives.module'
import { TooltipModule } from 'src/app/shared/directives/tooltip/tooltip.module'
import { SideMenuComponent } from './side-menu.component'

@NgModule({
    declarations: [SideMenuComponent],
    imports: [CommonModule, IonicModule, RouterModule, TooltipModule, DirectivesModule],
    exports: [SideMenuComponent]
})
export class SideMenuModule {}
