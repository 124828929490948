import { Injectable } from '@angular/core'
import { environment } from '../shared/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    constructor() {}

    imageUrl(array, from?: string) {
        return array
    }

    maskedString2ISODate(stringDate: string, stringTime?: string) {
        stringDate = stringDate.replace(/\//g, '')
        const date = +stringDate.substring(0, 2)
        const month = +stringDate.substring(2, 4)
        const year = +stringDate.substring(4)

        let newDate: Date

        if (stringTime) {
            const hour = +stringTime.substring(0, 2)
            const minute = +stringTime.substring(2)
            newDate = new Date(year, month - 1, date, hour, minute)
        } else {
            newDate = new Date(year, month - 1, date)
        }
        return newDate
    }

    buildMetaTags(route: string, title: string, description: string, image?: string) {
        return [
            { name: 'site_name', content: 'Margaret' },
            { name: 'author', content: 'margaret.fr' },
            {
                property: 'og:url',
                content: `${environment.shareRootUrl}${route}`
            },
            { property: 'og:locale', content: 'fr_FR' },
            { property: 'og:title', content: title },
            { property: 'og:type', content: 'article' },
            {
                property: 'og:image',
                content: image ? image : `${environment.shareImageUrl}`
            },
            { property: 'og:description', content: description },
            { property: 'fb:app_id', content: environment.facebookAppId },

            { name: 'twitter:creator', content: 'Margaret' },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: environment.twitterSite },
            { name: 'twitter:title', content: title },
            { name: 'twitter:description', content: description },
            {
                name: 'twitter:image',
                content: image ? image : `${environment.shareImageUrl}`
            }
        ]
    }
}
