import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { format } from 'date-fns'
import { AuthService } from 'src/app/services/auth.service'
import { LegalUseComponent } from '../../components/legal-use/legal-use.component'

@Component({
    selector: 'app-accept-cgu-modal',
    templateUrl: './accept-cgu-modal.component.html',
    styleUrls: ['./accept-cgu-modal.component.scss']
})
export class AcceptCguModalComponent implements OnInit {
    cgu: boolean

    constructor(private modalController: ModalController, private authSrv: AuthService) {}

    ngOnInit() {
        this.cgu = false
    }

    async openCGU() {
        const modal = await this.modalController.create({
            component: LegalUseComponent,
            cssClass: 'legal-use-modal'
        })

        await modal.present()
    }

    onAccept() {
        this.authSrv.cguAccepted = format(new Date(), 'yyyy-MM-dd')
        this.authSrv.manageCgu().subscribe(() => {
            this.modalController.dismiss({ cgu: this.cgu })
        })
    }

    onCancel() {
        this.authSrv.cguAccepted = null
        this.authSrv.manageCgu().subscribe(() => {
            this.modalController.dismiss({ cgu: false })
        })
    }
}
