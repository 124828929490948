import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { forkJoin, TimeoutError } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { environment } from '../shared/environments/environment'
import { MGEvent } from '../shared/models/event'
import { AuthService } from './auth.service'
import { CategoryService } from './category.service'
import { OrganizationService } from './organization.service'
import { StatsService } from './stats.service'
import { ToastService } from './toast.service'
import { UtilsService } from './utils.service'

@Injectable({ providedIn: 'root' })
export class EventService {
    FILTERS = {
        'Accès PMR': 'accessibility',
        'En Famille': 'family',
        Gratuit: 'free',
        'Chien accepté': 'dogs',
        Intérieur: 'indoor'
    }

    count: number
    currentEvent
    currentEventPreview = false

    constructor(
        private httpClient: HttpClient,
        private toastService: ToastService,
        private organizationService: OrganizationService,
        private statsService: StatsService,
        private authSrv: AuthService,
        private utilsSrv: UtilsService,
        private categoryService: CategoryService
    ) {}

    TIMEOUT_ERROR_MESSAGE = "'Une erreur s'est produite, veuillez ressayer plus tard.'"
    ABUSIVE_ERROR_MESSAGE = "'Votre annonce ne respecte pas les conditions d'utilisation.'"

    create(formData: FormData, type?: string) {
        return this.httpClient
            .post(
                `${environment.advertisersApiUrl}/organizations/${this.authSrv.currentOrganization.id}/events`,
                formData
            )
            .pipe(
                map(
                    (response: MGEvent) => {
                        console.log(`🕹️ > EventService > response =>`, response)
                        this.authSrv.currentOrganizationEvents.push(response)
                        return response
                    },
                    err => {
                        return err
                    }
                )
            )
    }

    getEvent(id: string) {
        return this.httpClient.get(`${environment.apiV3Url}/events/${id}`).pipe(
            map((event: any) => {
                // TODO : correct type
                return event.event
            })
        )
    }

    getEvents(searchQueryString = '') {
        return this.httpClient.get(`${environment.apiV3Url}/events/${searchQueryString}`).pipe(
            map((events: any) => {
                events = this.utilsSrv.imageUrl(events, 'events')
                return events
            })
        )
    }

    handleCreateOrUpdateError(response: any) {
        if (response instanceof TimeoutError) {
            this.toastService.createError(this.TIMEOUT_ERROR_MESSAGE, 3000)
            return
        }

        if (response.error.statusCode === 400 && response.error.message === 'Abusive content') {
            this.toastService.createError(this.ABUSIVE_ERROR_MESSAGE, 3000)
            return
        }

        this.toastService.createError(
            "Une erreur s'est produite. Veuillez ressayer plus tard.",
            3000
        )
    }

    // TODO: type it
    update(formData) {
        return this.httpClient
            .put(
                `${environment.advertisersApiUrl}/organizations/${this.authSrv.currentOrganization.id}/events`,
                formData
            )
            .pipe(
                map((data: any) => {
                    const editedEvent = this.authSrv.currentOrganizationEvents.find(event => {
                        return event.id == data.id
                    })
                    if (editedEvent) {
                        const category = this.categoryService.categories.find(category => {
                            return category.id == data.category.id
                        })
                        data.category = {
                            id: category.id,
                            label: category.label
                        }
                        const idx = this.authSrv.currentOrganizationEvents.indexOf(editedEvent)
                        this.authSrv.currentOrganizationEvents.splice(idx, 1, data)
                    }

                    return data
                })
            )
    }

    delete(eventId: number, organizationId: number) {
        return this.httpClient
            .delete(
                `${environment.advertisersApiUrl}/organizations/${organizationId}/events/${eventId}`
            )
            .pipe(
                map(res => {
                    return res
                })
            )
    }

    updateStatus(statusId: number, eventUuid: string, organizationUnique: string) {
        const formData = new FormData()
        formData.append('statusId', statusId.toString())
        formData.append('uniqueid', eventUuid)

        return this.httpClient
            .put(`${environment.apiV3Url}/events`, formData)
            .pipe(
                map(data => {
                    return data
                })
            )
            .pipe(
                mergeMap(data => {
                    return forkJoin({
                        orga: this.organizationService.getOrganization(organizationUnique),
                        stats: this.statsService.getOrganizationStats(organizationUnique)
                    })
                })
            )
    }

    getStats(eventId: string) {
        return this.httpClient.get(`${environment.apiV3Url}/statistics/${eventId}`).pipe(
            map((stats: any) => {
                return stats
            })
        )
    }
}
