import { AfterViewInit, Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { SessionService } from 'src/app/services/session.service'
import { UserService } from 'src/app/services/user.service'

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, AfterViewInit {
    authPage: string

    constructor(
        private modalController: ModalController,
        public userSrv: UserService,
        public sessionSrv: SessionService
    ) {}

    ngOnInit() {}

    ngAfterViewInit(): void {
        // if (this.authPage) {
        //     this.sessionSrv.authPage = undefined
        // }
    }

    onClose() {
        this.modalController.dismiss()
    }

    // onToggle(page: string) {
    //     this.authPage = page
    // }

    getTitle(): string {
        let title = null
        switch (this.sessionSrv.authPage) {
            case 'omni':
                title = 'Identification'
                break

            case 'login':
                title = 'Connexion'
                break

            case 'signup':
                title = 'Inscription gratuite'
                break

            case 'password-reset':
                title = 'Mot de passe'
                break

            case 'password-new':
                title = 'Mot de passe'
                break

            default:
                break
        }
        return title
    }
}
