<ion-content>
    <ion-grid>
        <ion-row class="pt-10 pb-20" class="pt-10 pb-20">
            <ion-col sizeXs="12" sizeLg="4" offsetXs="0" offsetLg="4">
                <ng-container *ngIf="!doubleAuth">
                    <ion-row>
                        <ion-col size="6">
                            <ion-button expand="block" color="dark" (click)="onAppleSignin()">
                                <ion-icon name="logo-apple"></ion-icon>
                            </ion-button>
                        </ion-col>
                        <ion-col size="6">
                            <ion-button expand="block" color="google" (click)="onGoogleSignin()">
                                <ion-icon name="logo-google"></ion-icon>
                            </ion-button>
                        </ion-col>
                        <!-- <ion-col size="4">
                            <ion-button
                                expand="block"
                                color="facebook"
                                (click)="onFacebookSignin()"
                            >
                                <ion-icon name="logo-facebook"></ion-icon>
                            </ion-button>
                        </ion-col> -->
                    </ion-row>
                </ng-container>

                <ng-container *ngIf="doubleAuth">
                    <h1 class="ion-text-center">
                        <ion-text color="primary">Authentification à deux facteurs</ion-text>
                    </h1>
                    <p class="text-center my-2">
                        Vous avez activé l'authentification à double facteur.
                        <br />
                        Afin de vous connecter, veuillez saisir le code reçu par email.
                    </p>
                    <input label="Code reçu par email *" class="text-center" [(ngModel)]="mfa" />

                    <div class="my-6">
                        <ion-button expand="block" color="orange" (click)="checkMfa()">
                            Vérifier le code
                        </ion-button>
                    </div>
                </ng-container>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!doubleAuth">
            <ion-col sizeXs="12" sizeLg="6" class="px-10">
                <h1 class="ion-text-center">
                    <ion-text color="primary">Connexion</ion-text>
                </h1>
                <form [formGroup]="loginForm" autocomplete="off">
                    <ion-label class="no-item-label">Adresse e-mail</ion-label>
                    <input
                        label="Adresse e-mail"
                        required
                        type="email"
                        formControlName="email"
                        placeholder="Adresse e-mail"
                        autocomplete="username"
                    />
                    <!-- <ion-input
                        label="Adresse e-mail"
                        required
                        type="email"
                        formControlName="email"
                        placeholder="Adresse e-mail"
                    ></ion-input> -->
                    <div
                        *ngIf="
                            loginForm.get('email').invalid &&
                            (loginForm.get('email').dirty || loginForm.get('email').touched)
                        "
                    >
                        <ion-text color="danger">L'adresse e-mail n'est pas valide.</ion-text>
                    </div>
                    <div class="password">
                        <ion-label class="no-item-label">Mot de passe</ion-label>
                        <input
                            required
                            [type]="passwordType"
                            formControlName="password"
                            placeholder="Mot de passe"
                            autocomplete="current-password"
                        />
                        <!-- <ion-input
                            [type]="passwordType"
                            placeholder="Mot de passe"
                            required
                            formControlName="password"
                        ></ion-input> -->
                        <ion-icon
                            [name]="passwordType == 'password' ? 'eye' : 'eye-off'"
                            slot="end"
                            class="password-icon"
                            (click)="onShowPassword('pass')"
                        ></ion-icon>
                    </div>
                    <div
                        *ngIf="
                            loginForm.get('password').invalid &&
                            (loginForm.get('password').dirty || loginForm.get('password').touched)
                        "
                    >
                        <ion-text color="danger">Le mot de passe est obligatoire.</ion-text>
                    </div>
                    <ion-button expand="block" color="orange" (click)="onSubmit()">
                        Se connecter
                    </ion-button>
                </form>
                <ion-button (click)="onPassword()" expand="block" fill="clear">
                    Mot de passe oublié ?
                </ion-button>
            </ion-col>
            <ion-col sizeXs="12" sizeLg="6">
                <ion-card color="orange">
                    <ion-card-content>
                        <h1>Rejoignez l'aventure Margaret dès aujourd'hui !</h1>
                        <ion-list lines="none">
                            <ion-item>
                                <ion-icon
                                    slot="start"
                                    name="checkmark-circle-outline"
                                    color="light"
                                ></ion-icon>
                                <ion-label>Mise en favoris</ion-label>
                            </ion-item>
                            <ion-item>
                                <ion-icon
                                    slot="start"
                                    name="checkmark-circle-outline"
                                    color="light"
                                ></ion-icon>
                                <ion-label>Alertes en temps réel</ion-label>
                            </ion-item>
                            <ion-item>
                                <ion-icon
                                    slot="start"
                                    name="checkmark-circle-outline"
                                    color="light"
                                ></ion-icon>
                                <ion-label>Gestion de votre organisation</ion-label>
                            </ion-item>
                            <ion-item>
                                <ion-icon
                                    slot="start"
                                    name="checkmark-circle-outline"
                                    color="light"
                                ></ion-icon>
                                <ion-label>Publication d'événements</ion-label>
                            </ion-item>
                        </ion-list>
                        <ion-button (click)="onSignup()" expand="block" fill="solid" color="light">
                            Commencer
                        </ion-button>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
