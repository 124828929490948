<!-- <div class="ion-flex ion-justify-content-center cursor-pointer ion-margin-bottom pb-5">
    <ion-avatar (click)="onShowMenu($event)" class="h-16 w-16">
        <img [src]="'/assets/icon/margaret.svg'" class="h-16 w-16" />
    </ion-avatar>
</div>
<hr /> -->
<div class="ion-flex ion-justify-content-center cursor-pointer ion-margin-bottom pt-28 pb-20">
    <ion-avatar (click)="onShowMenu($event)" class="h-16 w-16">
        <img
            [src]="authService.currentOrganization?.logo || '/assets/icon/margaret.svg'"
            class="h-16 w-16"
        />
        <ion-badge color="light">
            <ion-icon name="sync-outline"></ion-icon>
        </ion-badge>
    </ion-avatar>
</div>
<div *ngIf="authService.currentOrganization != null">
    <div
        class="ion-flex ion-justify-content-center cursor-pointer"
        [routerLink]="['/dashboard']"
        tooltip="Tableau de bord"
        [position]="TooltipPosition.DYNAMIC"
        [mgButtonState]="'dashboard'"
    >
        <ion-icon
            name="speedometer-outline"
            size="large"
            [color]="sessionSrv.currentButton == 'dashboard' ? 'primary' : 'medium'"
            class="pb-5"
        ></ion-icon>
    </div>
    <div
        class="ion-flex ion-justify-content-center cursor-pointer"
        [routerLink]="['/dashboard/members']"
        tooltip="Gestion des membres"
        [position]="TooltipPosition.DYNAMIC"
        [mgButtonState]="'members'"
    >
        <ion-icon
            name="people-outline"
            size="large"
            [color]="sessionSrv.currentButton == 'members' ? 'primary' : 'medium'"
            class="pb-5"
        ></ion-icon>
    </div>
    <div
        class="ion-flex ion-justify-content-center cursor-pointer"
        [routerLink]="['/dashboard/devices']"
        tooltip="Gestion des périphériques"
        [position]="TooltipPosition.DYNAMIC"
        [mgButtonState]="'devices'"
    >
        <ion-icon
            name="phone-portrait-outline"
            size="large"
            [color]="sessionSrv.currentButton == 'devices' ? 'primary' : 'medium'"
            class="pb-5"
        ></ion-icon>
    </div>
    <!-- <div
        class="ion-flex ion-justify-content-center cursor-pointer"
        routerLink="/organizations/edit"
        tooltip="Éditer l'organisation"
        [position]="TooltipPosition.DYNAMIC"
        [mgButtonState]="'orga'"
    >
        <ion-icon
            name="create-outline"
            size="large"
            [color]="sessionSrv.currentButton == 'orga' ? 'primary' : 'medium'"
            class="pb-5"
        ></ion-icon>
    </div> -->
</div>

<hr />

<div
    class="ion-flex ion-justify-content-center cursor-pointer pt-5"
    routerLink="/profile/edit"
    tooltip="Profil"
    [position]="TooltipPosition.DYNAMIC"
    [mgButtonState]="'profile'"
>
    <ion-icon
        name="person-circle-outline"
        size="large"
        [color]="sessionSrv.currentButton == 'profile' ? 'primary' : 'medium'"
        class="pb-5"
    ></ion-icon>
</div>
<div
    class="ion-flex ion-justify-content-center cursor-pointer"
    (click)="logout()"
    tooltip="Déconnexion"
    [position]="TooltipPosition.DYNAMIC"
>
    <ion-icon name="power-outline" size="large" color="danger" class="pb-5"></ion-icon>
</div>
