import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { environment } from '../shared/environments/environment'
import { Category } from '../shared/models/category.model'

@Injectable({ providedIn: 'root' })
export class CategoryService {
    categories: Category[]

    constructor(private httpClient: HttpClient) {}

    getCategories() {
        return this.httpClient
            .get<Category[]>(`${environment.advertisersApiUrl}/utils/categories`)
            .pipe(
                map(data => {
                    this.categories = data
                    this.categories.unshift({
                        id: 10000,
                        name: 'recents',
                        label: 'Prochainement',
                        keywords: '',
                        disp_order: 0
                    })
                })
            )
    }
}
