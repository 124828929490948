import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { environment } from '../shared/environments/environment'

@Injectable({ providedIn: 'root' })
export class StatsService {
    constructor(private httpClient: HttpClient) {}

    getOrganizationStats(id: number | string) {
        return this.httpClient
            .get(`${environment.advertisersApiUrl}/stats/organizations/${id}`)
            .pipe(
                map(data => {
                    return data
                })
            )
    }
}
