import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ButtonStateDirective } from './button-state.directive'

@NgModule({
    declarations: [ButtonStateDirective],
    imports: [CommonModule],
    exports: [ButtonStateDirective]
})
export class DirectivesModule {}
