import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { MGOrganization } from 'src/app/shared/models/organization'

@Component({
    selector: 'app-organizations-menu',
    templateUrl: './organizations-menu.component.html',
    styleUrls: ['./organizations-menu.component.scss']
})
export class OrganizationsMenuComponent implements OnInit {
    constructor(public authSrv: AuthService, private router: Router) {}

    ngOnInit() {}

    onChangeOrganization(organization: MGOrganization) {
        this.router.navigate(['/dashboard'])
        this.authSrv.changeOrganization(organization).subscribe()
    }

    newOrganization() {
        this.authSrv.currentOrganization = null
        // this.authSrv.currentOrganizationData = null

        this.router.navigate(['/organizations/new'])
    }
}
