import { Component, OnInit } from '@angular/core'
import { AlertController, ModalController, PopoverController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { SessionService } from 'src/app/services/session.service'
import { TooltipPosition } from 'src/app/shared/directives/tooltip/tooltip.enums'
import { CrossLogoutComponent } from '../cross-logout/cross-logout.component'
import { OrganizationsMenuComponent } from '../organizations-menu/organizations-menu.component'

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
    TooltipPosition: typeof TooltipPosition = TooltipPosition

    constructor(
        private popoverController: PopoverController,
        public authService: AuthService,
        public sessionSrv: SessionService,
        private alertCtrl: AlertController,
        private modalController: ModalController
    ) {}

    ngOnInit() {}

    async onShowMenu(ev: any) {
        const popover = await this.popoverController.create({
            component: OrganizationsMenuComponent,
            dismissOnSelect: true,
            event: ev,
            translucent: true
        })
        await popover.present()
    }

    async logout() {
        const alert = await this.alertCtrl.create({
            header: 'Déconnexion',
            message: 'Souhaitez-vous vraiment vous déconnecter de Margaret ?',
            buttons: [
                {
                    text: 'Oui',
                    role: 'danger',
                    handler: async () => {
                        const modal = await this.modalController.create({
                            component: CrossLogoutComponent,
                            cssClass: 'logout-modal'
                        })
                        await modal.present()
                        setTimeout(() => {
                            modal.dismiss()
                        }, 1000)
                        this.authService.logout().subscribe(() => {})
                    }
                },
                { text: 'Non', role: 'cancel' }
            ]
        })

        await alert.present()
    }

    toggleButton(button: string) {
        this.sessionSrv.currentButton = button
    }
}
