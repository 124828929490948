import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { EventFormModule } from '../../components/event-form/event-form.module'
import { OnboardingModalComponent } from './onboarding-modal.component'

@NgModule({
    declarations: [OnboardingModalComponent],
    imports: [CommonModule, IonicModule, EventFormModule]
})
export class OnboardingModalModule {}
