import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { SessionService } from 'src/app/services/session.service'
import { PasswordValidator } from 'src/app/validators/password.validator'

@Component({
    selector: 'app-password-new',
    templateUrl: './password-new.component.html',
    styleUrls: ['./password-new.component.scss']
})
export class PasswordNewComponent implements OnInit {
    token: string
    tokenForModal

    loginForm: FormGroup
    passwordError = ''
    // passwordValid = false

    passwordType: 'text' | 'password' = 'password'
    passwordConfirmType: 'text' | 'password' = 'password'
    passwordOk: boolean

    constructor(
        private route: ActivatedRoute,
        private authSrv: AuthService,
        private toastCtrl: ToastController,
        private router: Router,
        private passwordValidator: PasswordValidator,
        private sessionSrv: SessionService
    ) {}

    ngOnInit() {
        if (this.route.snapshot.paramMap.get('token')) {
            this.token = this.route.snapshot.paramMap.get('token')
        } else {
            this.token = this.sessionSrv.changePasswordJwt
        }
        this.loginForm = new FormGroup({
            password: new FormControl('', [Validators.required]),
            confirm: new FormControl('', [Validators.required])
        })
    }

    onShowPassword(input: string) {
        switch (input) {
            case 'pass':
                this.passwordType = this.passwordType == 'text' ? 'password' : 'text'

                break

            case 'conf':
                this.passwordConfirmType = this.passwordConfirmType == 'text' ? 'password' : 'text'
                break

            default:
                break
        }
    }

    checkPassword() {
        this.passwordError =
            this.loginForm.value.password.length === 0 && this.loginForm.value.confirm.length === 0
                ? 'Merci de renseigner un mot de passe valide'
                : null
        this.passwordError =
            this.loginForm.value.password != this.loginForm.value.confirm
                ? 'Le mot de passe et la confirmation ne correspondent pas.'
                : null
    }

    async submit() {
        const policies = this.passwordValidator.checkPasswordPolicy(
            this.loginForm.value.password
        ).policies
        const passwordValid = this.passwordValidator.checkPasswordPolicy(
            this.loginForm.value.confirm
        ).valid

        const equalsPasswords =
            this.loginForm.value.password == this.loginForm.value.confirm ? true : false
        if (
            this.passwordOk &&
            this.passwordError == null &&
            policies &&
            passwordValid &&
            equalsPasswords
        ) {
            this.authSrv.passwordNewConfirm(this.loginForm.value.confirm, this.token).subscribe(
                async () => {
                    const toast = await this.toastCtrl.create({
                        message: `Votre mot de passe a bien été modifié, vous allez pouvoir vous connecter. Merci de patienter...`,
                        duration: 3000,
                        color: 'primary'
                    })
                    toast.onDidDismiss().then(() => {
                        this.sessionSrv.showAuthModal = true
                        this.router.navigate(['/login'])
                    })
                    toast.present()
                },
                async err => {
                    if (err.status == 401) {
                        const toast = await this.toastCtrl.create({
                            message: `Cette action n'est pas autorisée. Le lien a déjà été utilisé ou est périmé.`,
                            duration: 3000,
                            color: 'danger'
                        })
                        toast.present()
                    }
                }
            )
        }
    }

    isPasswordOk(event) {
        this.passwordOk = event
    }

    cancel() {
        this.router.navigate(['/home'])
    }
}
