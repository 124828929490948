import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { PushNotifications } from '@capacitor/push-notifications'
import { ToastController } from '@ionic/angular'
import { formatDistance, fromUnixTime, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging'
import { of, throwError } from 'rxjs'
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { environment } from '../shared/environments/environment'
import { CheckDoubleAuthInput } from '../shared/models/auth/check-double-auth-input.model'
import { CreateUserInput } from '../shared/models/auth/create-user-input.model'
import { CurrentUser } from '../shared/models/auth/current-user.model'
import { LoggedDevice } from '../shared/models/auth/logged-device.model'
import { LoginData } from '../shared/models/auth/login-data.model'
import { LoginUserInput } from '../shared/models/auth/login-user-input.model'
import { MGEvent } from '../shared/models/event'
import { MGOrganization } from '../shared/models/organization'
import { MGOrganizationMember } from '../shared/models/organization-member'
import { MGUser } from '../shared/models/user'
import { User } from '../shared/models/user/user.model'
import { CategoryService } from './category.service'
import { JwtService } from './jwt.service'
import { SearchService } from './search.service'
import { StatsService } from './stats.service'
import { ToastService } from './toast.service'
import { UserService } from './user.service'

@Injectable({ providedIn: 'root' })
export class AuthService {
    cguAccepted: string = null
    loginInProgress = false
    isAuth = false
    token: string
    currentUser: CurrentUser
    currentOrganization: MGOrganization
    currentOrganizationData // TODO move to orga srv
    currentOrganizationStats // TODO move to orga srv
    currentOrganizationSubscriptionDistance // TODO move to orga srv
    currentOrganizationMembers: MGOrganizationMember[] // TODO move to orga srv
    currentOrganizationEvents: MGEvent[] // TODO move to orga srv
    currentUserOrganizations: MGOrganization[] = []
    loginFormCredentials: LoginUserInput

    private tokenTimer: any

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private toastService: ToastService,
        private jwtService: JwtService,
        private statsService: StatsService,
        private userSrv: UserService,
        private toastController: ToastController,
        // private alertSrv: AlertService,
        private searchSrv: SearchService,
        private categorySrv: CategoryService
    ) {}

    getMargaretToken() {
        return this.token
    }

    isLogged() {
        return this.currentUser !== undefined
    }

    passwordReset(mail: string) {
        return this.httpClient.post(`${environment.apiV3Url}/users/forgot-password`, { mail }).pipe(
            map(
                (response: any) => {
                    return response
                },
                err => {
                    return err
                }
            )
        )
    }

    passwordNewConfirm(newPassword: string, token: string) {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + token
        })

        return this.httpClient
            .post(`${environment.apiV3Url}/users/reset-password`, { newPassword }, { headers })
            .pipe(
                map(
                    (response: any) => {
                        return response
                    },
                    err => {
                        return err
                    }
                )
            )
    }

    async getFcmToken() {
        const supported = await isSupported()
        if (!supported) {
            return ''
        }
        const firebaseConfig = {
            apiKey: 'AIzaSyBZiMLIuhJG4lvVyLQU4S2X-EUiTzLMaQQ',
            authDomain: 'margaret-c8c69.firebaseapp.com',
            projectId: 'margaret-c8c69',
            storageBucket: 'margaret-c8c69.appspot.com',
            messagingSenderId: '18216916625',
            appId: '1:18216916625:web:cc544ed2d64c73ebea5130',
            measurementId: 'G-14785FLSRN',
            vapidKey:
                'BH4rQIjNzy4EwwFR9mTMH428r77IB5FgWvl9yNHHz4jhwxJlP8attaWu_bjrw9lwspK7fswR4lmogFLnPlL57d0'
        }
        let fcmToken

        const app = initializeApp(firebaseConfig)
        const messaging = getMessaging(app)
        onMessage(messaging, payload => {
            // if (payload.data.type == 'alert') this.alertSrv.getAlerts().subscribe()
            new Notification(payload.notification.title, {
                body: payload.notification.body,
                icon: payload.notification.image
            })
        })
        await getToken(messaging, { vapidKey: firebaseConfig.vapidKey }).then(
            token => {
                fcmToken = token
            },
            err => {
                fcmToken = ''
            }
        )

        return fcmToken
    }

    private async registerPush() {
        await PushNotifications.requestPermissions().then(permission => {
            if (permission.receive == 'granted') {
                PushNotifications.register()
            } else {
                // No permission for push granted
            }
        })
        await PushNotifications.addListener('registration', () => {})
        await PushNotifications.addListener('registrationError', () => {})
        await PushNotifications.addListener('pushNotificationReceived', async notification => {
            // if (notification.data.type == 'alert') this.alertSrv.getAlerts().subscribe()
            this.showNotification(notification)
        })
        await PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async notification => {
                console.log('Action performed: ' + JSON.stringify(notification.notification))
            }
        )
    }

    async showNotification(notification) {
        const toast = await this.toastController.create({
            message: notification.body,
            color: 'primary',
            duration: 5000
        })
        toast.present()
    }

    login(credentials: LoginUserInput) {
        return this.httpClient.post<LoginData>(`${environment.apiV3Url}/login`, credentials).pipe(
            map(data => {
                if (data.marker) {
                    return throwError({
                        reason: 'mfa',
                        marker: data.marker
                    })
                }
                if (data.access_token) {
                    return data.access_token
                }
            }),
            catchError(err => {
                if (err.error?.statusCode == 401) {
                    this.toastService.createError('Adresse e-mail ou mot de passe non valide', 2000)
                } else if (err.reason == 'mfa') {
                    return throwError(err)
                } else {
                    this.toastService.createError('Une erreur est survenue', 2000)
                }
                return throwError(err)
            })
        )
    }

    checkDoubleAuth(input: CheckDoubleAuthInput) {
        return this.httpClient
            .post<LoginData>(`${environment.apiV3Url}/users/double-auth`, input)
            .pipe(
                switchMap(data => {
                    if (data.access_token) {
                        this.loginUser(data.access_token)
                        return of(this.currentUser)
                    }
                }),
                map(user => {
                    return user
                })
            )
    }

    activateUser(userUid: string, totpCode: string) {
        return this.httpClient
            .put(`${environment.apiV3Url}/users/activate`, { userUid, totpCode })
            .pipe(
                map(
                    (response: any) => {
                        return response
                    },
                    err => {
                        return err
                    }
                )
            )
    }

    loginUser(access_token: string) {
        const decodedToken = this.jwtService.decode(access_token)

        if (!decodedToken) {
            this.router.navigate(['/home'])
            return
        }

        this.isAuth = true
        this.token = access_token

        this.cguAccepted = decodedToken.accepted_terms
        const uniqueid = decodedToken.uniqueid
        const exp = decodedToken.exp
        const picture = decodedToken.picture
        const since = decodedToken.since
        const mfa = decodedToken.mfa

        this.userSrv.getUser(uniqueid).subscribe((res: MGUser) => {
            const idx = this.userSrv.genders.findIndex(gender => {
                return gender.label == res.gender
            })

            this.currentUser = {
                email: res.mail,
                firstname: res.firstname,
                lastname: res.lastname,
                id: res.id,
                uniqueId: uniqueid,
                phone: res.phone,
                avatar: picture,
                since,
                mfa,
                location: res.location,
                dob: res.dob,
                gender: this.userSrv.genders[idx]
            }

            const expirationDate = fromUnixTime(exp)
            const expiresIn = this.expiresIn(expirationDate)
            this.setAuthTimer(expiresIn / 1000)

            this.jwtService.saveAuthData(access_token, expirationDate, this.currentUser)
            this.isAuth = true
        })
    }

    toggleDoubleAuth() {
        return this.httpClient.post(`${environment.apiV3Url}/toggle_double_auth`, {}).pipe(
            map(data => {
                return data
            })
        )
    }

    loggedDevices() {
        return this.httpClient.get<LoggedDevice[]>(`${environment.apiV3Url}/logged_devices`).pipe(
            map(data => {
                return data
            })
        )
    }

    logoutDevice(token: string) {
        return this.httpClient.get(`${environment.apiV3Url}/logout/${token}`).pipe(
            map(data => {
                return data
            })
        )
    }

    logout() {
        return this.httpClient.post(`${environment.apiV3Url}/logout`, null).pipe(
            tap(() => {
                this.logUserOut()
            })
        )
    }

    logUserOut() {
        this.searchSrv.reset()
        clearTimeout(this.tokenTimer)
        this.isAuth = false
        this.token = ''
        this.currentUser = null
        this.isAuth = false
        this.currentUserOrganizations = []
        this.jwtService.clearAuthData()
        this.currentOrganization = undefined
        this.currentOrganizationData = undefined
        this.currentOrganizationStats = undefined
        this.currentOrganizationMembers = undefined
        // this.alertSrv.currentAlerts = []
        // this.alertSrv.organizations = []
        this.loginInProgress = false

        this.router.navigate(['/home'])
    }

    createUser(input: CreateUserInput) {
        return this.httpClient.post<User>(`${environment.apiV3Url}/users`, input).pipe(
            map(user => {
                return user
            })
        )
    }

    async restoreAuth() {
        localStorage.removeItem('stripeStatus')
        // const stripeStatus = localStorage.getItem('stripeStatus')
        const authData = this.jwtService.getAuthData()

        if (!authData) {
            // this.navCtrl.navigateRoot('home')
            return
        }

        this.token = authData.token

        const expiresIn = this.expiresIn(authData.expirationDate)

        if (expiresIn > 0) {
            this.loginUser(this.token)
            this.isAuth = true
            this.token = authData.token
            this.currentUser = authData.user

            this.setAuthTimer(expiresIn / 1000)
            this.categorySrv.getCategories().subscribe()
            this.router.navigate(['/dashboard'])
            // stripeStatus != 'progress' ? this.router.navigate(['/dashboard']) : null
        } else {
            this.logUserOut()
        }
    }

    // getOrganizations() {
    //     return this.httpClient.get(`${environment.advertisersApiUrl}/organizations`).pipe(
    //         map((organizations: any) => {
    //             this.currentUserOrganizations = organizations
    //             return organizations
    //         })
    //     )
    // }

    changeCurrentUser(currentUser: CurrentUser) {
        this.jwtService.saveCurrentUser(currentUser)
        this.currentUser = currentUser
    }

    changeCurrentUserOrganizations(organizations: MGOrganization[]) {
        this.currentUserOrganizations = organizations
    }

    getOrganizationEvents() {
        return this.httpClient
            .get(
                `${environment.advertisersApiUrl}/organizations/${this.currentOrganization.id}/events`
            )
            .pipe(
                map((events: any) => {
                    return events
                })
            )
    }

    changeOrganization(organization: MGOrganization) {
        this.currentOrganization = organization

        if (organization.subscriptionDate) {
            const subDate = parseISO(this.currentOrganization.subscriptionDate)
            const today = new Date()
            this.currentOrganizationSubscriptionDistance = formatDistance(subDate, today, {
                locale: fr
            })
        }

        return this.statsService.getOrganizationStats(this.currentOrganization.id).pipe(
            mergeMap((stats: any) => {
                this.currentOrganizationStats = stats
                return this.getOrganizationEvents()
            }),
            mergeMap((events: any) => {
                this.currentOrganizationEvents = events
                return this.getMembers(organization.id)
            }),
            map((members: MGOrganizationMember[]) => {
                return (this.currentOrganizationMembers = members)
            })
        )
    }

    expiresIn(date: Date) {
        const now = new Date()
        return date.getTime() - now.getTime()
    }

    private setAuthTimer(seconds: number) {
        this.tokenTimer = setTimeout(() => {
            this.logUserOut()
        }, seconds * 1000)
    }

    getMembers(organizationId: any) {
        return this.httpClient
            .get<MGOrganizationMember[]>(
                `${environment.advertisersApiUrl}/organizations/${organizationId}/members`
            )
            .pipe(
                map(members => {
                    return members
                })
            )
    }

    getOrganization(organizationId: string | number) {
        return this.httpClient
            .get(`${environment.advertisersApiUrl}/organizations/${organizationId}`)
            .pipe(
                map(organization => {
                    return organization
                })
            )
    }

    socialLogin(provider, body) {
        return this.httpClient.post(`${environment.apiV3Url}/login/${provider}`, body).pipe(
            switchMap((res: any) => {
                const token = res.access_token
                this.loginUser(token)
                return of(this.currentUser)
            }),
            map(user => {
                return user
            }),
            catchError(err => {
                this.toastService.createError('Une erreur est survenue', 2000)
                return throwError(err)
            })
        )
    }

    manageCgu() {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.token
        })
        return this.httpClient
            .put(
                `${environment.apiV3Url}/users/terms`,
                { accepted_terms_date: this.cguAccepted },
                { headers }
            )
            .pipe(
                map(res => {
                    return res
                })
            )
    }

    deleteUser() {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.token
        })
        return this.httpClient.delete(`${environment.apiV3Url}/users`, { headers }).pipe(
            map(
                (response: any) => {
                    this.logUserOut()
                    return response
                },
                err => {
                    return err
                }
            )
        )
    }
}
